/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { history as historyPropTypes } from "history-prop-types";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { putSurvey } from "../../db";
import "./style.css";

const LabelBlock = ({ data, id }) => (
    <label htmlFor={id} style={{ paddingRight: "1em" }}>{ data }</label>
);

const ErrorBlock = ({ error }) => (
    <div className="error" style={{ color: "red" }}>{ error }</div>
);

const SurveyTextInput = ({ label, ...props }) =>
{
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    const { id, name } = props;

    return (
        <div>
            { label && <LabelBlock data={label} id={id || name} /> }
            <input className="text-input" style={{ float: "right" }} {...field} {...props} />
            { meta.error && <ErrorBlock error={meta.error} />}
        </div>
    );
};
const SurveySelectInput = ({ label, ...props }) =>
{
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    const { id, name } = props;

    return (
        <div>
            { label && <LabelBlock data={label} id={id || name} /> }
            <select className="select-input" style={{ float: "right" }} {...field} {...props} />
            { meta.error && <ErrorBlock error={meta.error} />}
        </div>
    );
};

const SurveyQRCodeInput = ({ label, ...props }) =>
{
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field] = useField(props);

    return (
        <div>
            <input className="text-input" type="hidden" readOnly style={{ float: "right" }} {...field} {...props} />
        </div>
    );
};

export default class LeadGenActivityView extends Component
{
    lastSurveyId = -1;

    constructor(props)
    {
        super(props);
        this.state = {
            validationSchema: null
        };
    }

    componentDidMount()
    {
        const { campaign } = this.props;

        if (!campaign)
        {
            return;
        }

        const validationSchema = {};
        const { questions } = campaign.lead_generation;

        questions.map(({ category, question_tag: tag, is_answer_required: isAnswerRequired, id }) =>
        {
            let yupObj = null;
            switch (category)
            {
                case "custom_text":
                case "name":
                    if (tag !== "team")
                    {
                        yupObj = Yup.string().max(255, "Must be 255 characters or less");
                        if (isAnswerRequired)
                        {
                            yupObj = yupObj.required("Required").min(1, "This field is required!");
                        }
                        validationSchema[id] = yupObj;
                    }
                    break;

                case "email":
                    yupObj = Yup.string().max(
                        255, "Must be 255 characters or less").email("Not a valid email address!"
                    );
                    if (isAnswerRequired)
                    {
                        yupObj = yupObj.required("Required").min(1, "This field is required!");
                    }

                    validationSchema[id] = yupObj;
                    break;

                case "telephone":
                    yupObj = Yup.string().max(255, "Must be 255 characters or less").phone();
                    if (isAnswerRequired)
                    {
                        yupObj = yupObj.required("Required").min(1, "This field is required!");
                    }

                    validationSchema[id] = yupObj;
                    break;

                case "qrcode":
                    break;

                default:
                    validationSchema[id] = Yup.string();
                    break;
            }

            return id;
        });

        this.setState({ validationSchema: Yup.object(validationSchema) });
    }

    onSubmit = (values) =>
    {
        const { campaign, setLastSurveyID, imageWorker, history } = this.props;

        const answers = Object.entries(values).map((v) => ({ id: parseInt(v[0], 10), answer: v[1] }));
        const newSurvey = {
            campaignID: campaign.id,
            galleryID: campaign.gallery_id,
            created_at: (new Date()).getTime(),
            isUploaded: 0,
            deviceID: campaign.device_code,
            survey: answers
        };

        putSurvey(newSurvey).then((res) => setLastSurveyID(res));
        imageWorker.postMessage({ messageType: "upload_surveys" });
        // videoWorker.postMessage({ messageType: "upload_surveys" }); // TODO
        (campaign.activation_mode === "lead") ? history.goBack() : history.replace("/CameraActivity");
        alert("Thanks!");
    }

    renderSelect = ({ id: selectId, question, answers }) => (
        <div key={`TagkastQuestion_${selectId}`}>
            <SurveySelectInput
                label={question}
                name={selectId}
                type="text"
                placeholder={question}
                initialValue={answers[0].id}
            >
                {
                    answers.map(({ id, answer }) => <option key={`TagkastAnswer_${id}`} value={id}>{ answer }</option>)
                }
            </SurveySelectInput>
        </div>
    )

    renderTextInput = ({ id, question }) => (
        <div key={`TagkastQuestion_${id}`}>
            <SurveyTextInput label={question} name={id} type="text" placeholder={question} />
        </div>
    )

    generateQuickGuid = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    renderQRCodeInput = ({ id }) => (
        <div key={`TagkastQuestion_${id}`}>
            <SurveyQRCodeInput
                className="text-input"
                name={id}
                style={{ float: "right" }}
                initialValue={this.generateQuickGuid()}
            />
        </div>
    )

    render()
    {
        const { campaign } = this.props;

        if (!campaign)
        {
            return null;
        }

        const { questions } = campaign.lead_generation;
        const { validationSchema } = this.state;
        const initialV = {};

        const renderedQuestions = questions.map((question) =>
        {
            const { category } = question;
            let result = null;

            if (category === "custom_dropdown")
            {
                result = this.renderSelect(question);
            }
            else if (category === "name"
                || category === "email"
                || category === "telephone"
                || category === "custom_text")
            {
                result = this.renderTextInput(question);
            }
            else if (category === "qrcode")
            {
                result = this.renderQRCodeInput(question);
            }

            return result;
        });

        questions.map(({ category, id }) =>
        {
            initialV[id] = (category !== "qrcode") ? "" : this.generateQuickGuid();

            return {};
        });

        return (
            <div className="maxWidthHeight100 tagkastSurveyBackground LeadGenActivity">
                <Formik
                    initialValues={initialV}
                    validationSchema={validationSchema}
                    onSubmit={(values) => this.onSubmit(values)}
                >
                    <Form>
                        <div className="LeadGenActivity_Form">
                            <div className="LeadGenActivity_Inline">{renderedQuestions}</div>
                        </div>
                        <div className="LeadGenActivity_Flex">
                            <button type="submit">Submit</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        );
    }
}

LeadGenActivityView.defaultProps = {
    campaign: null,
    imageWorker: null,
    videoWorker: null,
    setLastSurveyID: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

LeadGenActivityView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    setLastSurveyID: PropTypes.func,
    history: PropTypes.shape(historyPropTypes)
};
