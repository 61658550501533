const Types = {
    SET_CAMPAIGN_ID: "SET_CAMPAIGN_ID",
    CLEAR_CAMPAIGN_ID: "CLEAR_CAMPAIGN_ID",
    SET_CAMPAIGN: "SET_CAMPAIGN",
    CLEAR_CAMPAIGN: "CLEAR_CAMPAIGN",
    SET_ORIENTATION: "SET_APP_ORIENTATION",
    SET_IMAGE_WORKER: "SET_IMAGE_WORKER",
    SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
    SET_IMAGE_URL: "SET_IMAGE_URL",
    SET_IMAGES: "SET_IMAGES",
    ADD_IMAGE: "ADD_IMAGE",
    HIDE_HEADER: "HIDE_HEADER",
    SHOW_HEADER: "SHOW_HEADER",
    SET_LAST_SURVEY_ID: "SET_LAST_SURVEY_ID",
    SET_JPG_LIST: "SET_JPG_LIST",
    SET_IS_PROCESS_PHOTOS_STARTED: "SET_IS_PROCESS_PHOTOS_STARTED",
    SET_IS_PROCESS_PHOTOS_READY: "SET_IS_PROCESS_PHOTOS_READY",
    CLEAR_STATE: "CLEAR_STATE",
    SET_JPG_LIST_LENGTH: "SET_JPG_LIST_LENGTH",
    SET_TEST_FLAG: "SET_TEST_FLAG",
    SET_VIDEO_WORKER: "SET_VIDEO_WORKER",
    SET_VIDEO_URL: "SET_VIDEO_URL",
    SET_VIDEOS: "SET_VIDEOS",
    ADD_VIDEO: "ADD_VIDEO",
    SET_ARTWORK_FRAME: "SET_ARTWORK_FRAME",
    SET_ARTWORK_GREENSCREEN_BACKGROUND: "SET_ARTWORK_GREENSCREEN_BACKGROUND",
    SET_ARTWORK_APP_BACKGROUND: "SET_ARTWORK_APP_BACKGROUND",
    SET_ARTWORK_FACE: "SET_ARTWORK_FACE",
    SET_CONTENT_TYPE: "SET_CONTENT_TYPE"
};

export const setCurrentCampaignID = (campaignID) => ({ type: Types.SET_CAMPAIGN_ID, payload: campaignID });

export const clearCurrentCampaignID = () => ({ type: Types.CLEAR_CAMPAIGN_ID, payload: 0 });

export const setCurrentCampaign = (campaign) => ({ type: Types.SET_CAMPAIGN, payload: campaign });

export const clearCurrentCampaign = () => ({ type: Types.CLEAR_CAMPAIGN, payload: 0 });

export const setOrientation = (orientation) => ({ type: Types.SET_ORIENTATION, payload: orientation });

export const setImageWorker = (imageWorker) => ({ type: Types.SET_IMAGE_WORKER, payload: imageWorker });

export const setUploadProgress = (filename, current, total) => ({
    type: Types.SET_UPLOAD_PROGRESS,
    payload: {
        filename: filename,
        uploaded: current,
        total: total,
        uploadProgress: ((current * 100) / total),
        uploadError: null
    } });

export const setImageURL = (filename, url, imageUrl) => ({
    type: Types.SET_IMAGE_URL,
    payload: {
        imageID: filename,
        url: url,
        image_url: imageUrl
    } });

export const setUploadError = (filename, error) => ({
    type: Types.SET_UPLOAD_PROGRESS,
    payload: {
        filename: filename,
        uploadError: error
    } });

export const setImages = (imagesFromDB) => ({ type: Types.SET_IMAGES, payload: imagesFromDB });

export const addImage = (newImage) => ({ type: Types.ADD_IMAGE, payload: newImage });

export const hideHeader = () => ({ type: Types.HIDE_HEADER, payload: null });

export const showHeader = () => ({ type: Types.SHOW_HEADER, payload: null });

export const setLastSurveyID = (surveyId) => ({ type: Types.SET_LAST_SURVEY_ID, payload: surveyId });

export const setJpgList = (jpgList) => ({ type: Types.SET_JPG_LIST, payload: jpgList });

export const setIsProcessPhotosStarted = (isProcessPhotosStarted) => ({
    type: Types.SET_IS_PROCESS_PHOTOS_STARTED,
    payload: isProcessPhotosStarted
});

export const setIsProcessPhotosReady = (isProcessPhotosReady) => ({
    type: Types.SET_IS_PROCESS_PHOTOS_READY,
    payload: isProcessPhotosReady
});

export const clearState = () => ({
    type: Types.CLEAR_STATE,
    payload: {
        isProcessPhotosStarted: false,
        isProcessPhotosReady: false,
        videos: [],
        uploads: []
    } });

export const setJpgListLength = (length) => ({ type: Types.SET_JPG_LIST_LENGTH, payload: length });

export const setTestFlag = (testFlag) => ({ type: Types.SET_TEST_FLAG, payload: testFlag });

export const setVideoWorker = (videoWorker) => ({ type: Types.SET_VIDEO_WORKER, payload: videoWorker });

export const setVideos = (videosFromDB) => ({ type: Types.SET_VIDEOS, payload: videosFromDB });

export const addVideo = (newVideo) => ({ type: Types.ADD_VIDEO, payload: newVideo });

export const setVideoURL = (filename, url, videoUrl) => ({
    type: Types.SET_VIDEO_URL,
    payload: {
        videoID: filename,
        url: url,
        video_url: videoUrl
    } });

export const setArtworkFrame = (url, index) => ({
    type: Types.SET_ARTWORK_FRAME,
    payload: {
        url: url,
        index: index
    } });

export const setArtworkGreenScreenBackground = (url, index) => ({
    type: Types.SET_ARTWORK_GREENSCREEN_BACKGROUND,
    payload: {
        url: url,
        index: index
    } });

export const setArtworkAppBackground = (url, index) => ({
    type: Types.SET_ARTWORK_APP_BACKGROUND,
    payload: {
        url: url,
        index: index
    } });

export const setArtworkFace = (url, index) => ({
    type: Types.SET_ARTWORK_FACE,
    payload: {
        url: url,
        index: index
    } });

export const setContentType = (contentType) => ({ type: Types.SET_CONTENT_TYPE, payload: contentType });

export default {
    setCurrentCampaign,
    clearCurrentCampaign,
    setCurrentCampaignID,
    clearCurrentCampaignID,
    setOrientation,
    setImageWorker,
    setUploadProgress,
    setUploadError,
    setLastSurveyID,
    setImages,
    addImage,
    setImageURL,
    hideHeader,
    showHeader,
    setIsProcessPhotosStarted,
    setIsProcessPhotosReady,
    clearState,
    setJpgListLength,
    setTestFlag,
    setVideoWorker,
    setVideos,
    addVideo,
    setVideoURL,
    setArtworkFrame,
    setArtworkGreenScreenBackground,
    setArtworkAppBackground,
    setArtworkFace,
    setContentType,
    Types
};
