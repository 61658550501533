import { connect } from "react-redux";
import { selectOrientation, selectCampaign } from "../../state/selectors";
import { setOrientation } from "../../state/actions";
import AppInfoView from "./AppInfo";

const mapStateToProps = (state) => ({
    appOrientation: selectOrientation(state) || "",
    lockOrientation: selectCampaign(state)?.force_orientation || ""
});

const mapDispatchToProps = (dispatch) => ({
    setAppOrientation: (appOrientation) => dispatch(setOrientation(appOrientation))
});

const AppInfo = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppInfoView);

export default AppInfo;
