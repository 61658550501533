import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    arrayBufferObjectToBlob,
    blobToArrayBufferObject,
    getArtwork,
    getCampaignImages,
    getCampaignVideos,
    updateImage,
    updateVideo
} from "../../db";
import Tiles from "../Tiles";
import "./style.css";

export default function HomeScreenActivityView({
    imageWorker, videoWorker, orientation, campaignID, setImages, setVideos })
{
    const [applicationBackground, setApplicationBackground] = useState("");

    useEffect(() =>
    {
        if (imageWorker && imageWorker.postMessage)
        {
            imageWorker.postMessage({ messageType: "upload_images" });
            imageWorker.postMessage({ messageType: "upload_surveys" });
        }
    }, [imageWorker]);

    useEffect(() =>
    {
        if (videoWorker && videoWorker.postMessage)
        {
            videoWorker.postMessage({ messageType: "upload_videos" });
            videoWorker.postMessage({ messageType: "upload_surveys" });
        }
    }, [videoWorker]);

    useEffect(() =>
    {
        if (orientation)
        {
            getArtwork(`app_background_${orientation}`).then((result) =>
            {
                try
                {
                    if (result != null)
                    {
                        const blob = arrayBufferObjectToBlob(result.data, result.mimeType);
                        setApplicationBackground(URL.createObjectURL(blob));
                    }
                }
                catch (error)
                {
                    console.error(error.toLocaleString());
                }
            });
        }
    }, [orientation]);

    useEffect(() =>
    {
        getCampaignImages(campaignID).then(async (result) =>
        {
            const { length } = result;
            const updatedImages = [];
            const images = [];

            for (let i = 0; i < length; i += 1)
            {
                const { id, image } = result[i];

                if (image && image instanceof Blob)
                {
                    images.push(blobToArrayBufferObject(image));
                    updatedImages.push(updateImage(id, { image }));
                }
            }
            await Promise.all(images);
            await Promise.all(updatedImages);
            setImages(result);
        });
    }, [campaignID, setImages]);

    useEffect(() =>
    {
        getCampaignVideos(campaignID).then(async (result) =>
        {
            const { length } = result;
            const updatedVideos = [];
            const videos = JSON.parse(JSON.stringify(result));

            for (let i = 0; i < length; i += 1)
            {
                const { id, video } = result[i];

                if (video)
                {
                    videos[i].video = arrayBufferObjectToBlob(video, video.mimeType);
                    updatedVideos.push(updateVideo(id, { video }));
                }
            }

            await Promise.all(updatedVideos);
            setVideos(videos);
        });
    }, [campaignID, setVideos]);

    return (
        <div className="maxWidthHeight100 HomeScreenActivity">
            <div className="maxWidthHeight100 widthHeight100 HomeScreenActivity_Img">
                { applicationBackground && (
                    <img
                        className="maxWidthHeight100 widthHeight100 heightFillAvailable"
                        src={applicationBackground}
                        alt="App Background"
                    />
                )}
            </div>
            <div className="container relativePosition HomeScreenActivity_Link">
                <link rel="manifest" href="/manifest.json" />
                <Tiles />
            </div>
        </div>
    );
}

HomeScreenActivityView.defaultProps = {
    campaignID: PropTypes.number.isRequired,
    orientation: "landscape",
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired,
    setImages: PropTypes.func.isRequired,
    setVideos: PropTypes.func.isRequired
};

HomeScreenActivityView.propTypes = {
    campaignID: PropTypes.number,
    orientation: PropTypes.string,
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    setImages: PropTypes.func,
    setVideos: PropTypes.func
};
