import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../db";
import { FrameChooser } from "../../CameraActivity/FrameChooser/FrameChooser";
import { Header } from "../Header/Header";
import { SVG_BTN } from "../ContentType/svg";
import "../style.css";

const NEXT_HISTORY = "/Frame";

export default function BackgroundView({ orientation, listLengthBackground, enableGreenScreen,
    setArtworkGreenScreenBackground, history })
{
    const [listBackground, setListBackground] = useState([]);
    const [indexBackground, setIndexBackground] = useState(-1);
    const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);
    const refImg = useRef(null);

    useEffect(() =>
    {
        if (indexBackground >= 0)
        {
            getArtwork(`greenscreen_background_${orientation}_${indexBackground}`).then((result) =>
            {
                if (result?.data != null)
                {
                    const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data));
                    setArtworkGreenScreenBackground(url, indexBackground);
                }
            });
        }
    }, [indexBackground, orientation, setArtworkGreenScreenBackground]);

    if (!enableGreenScreen)
    {
        return (
            <Redirect to={NEXT_HISTORY} />
        );
    }

    if (listLengthBackground)
    {
        if (listBackground.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("greenscreen").each((art) =>
            {
                if (art.member.endsWith(orientation))
                {
                    artworkList.push({
                        original: art.url,
                        thumbnail: art.url,
                        member: art.member
                    });
                }
            }).then(() =>
            {
                artworkList?.length && setListBackground(artworkList);

                if (artworkList?.length === 1)
                {
                    setIndexBackground(0);
                    history.push(NEXT_HISTORY);
                }
            });
        }

        if (listLengthBackground > 1)
        {
            return (
                <div role="main" className="ScreenSelect_Container">
                    <Header />
                    <span className="ScreenSelect_Header">Select your background!</span>

                    <div className="ScreenSelect_Description_Block">
                        <span className="ScreenSelect_Description_Text">
                            Your experience will replace the background in your photo.
                            Choose the background you would like.
                            You can update the background once the camera starts by pressing the &quot;BG&quot; button.
                        </span>
                        <span className="ScreenSelect_HelperText">
                            Click on the image to chose background
                        </span>
                        <FrameChooser
                            text=""
                            index={indexBackground >= 0 ? indexBackground : 0}
                            list={listBackground}
                            chooserModal={null}
                            onClick={() =>
                            {
                                const indexBackgroundNew = refImg.current.getCurrentIndex();

                                if (indexBackgroundNew !== indexBackground)
                                {
                                    setIndexBackground(indexBackgroundNew);
                                    setIsBackgroundSelected(true);
                                }

                                const imageList = document.getElementsByClassName("image-gallery-image");

                                for (let i = 0; i < imageList.length; i++)
                                {
                                    const classes = imageList[i].classList;

                                    (i === indexBackgroundNew) ? classes.add("active") : classes.remove("active");
                                }
                            }}
                            refVar={refImg}
                            hideButtons
                        />
                        <button
                            type="button"
                            className="ScreenSelect_Btn"
                            onClick={(e) =>
                            {
                                e.preventDefault();
                                history.push(NEXT_HISTORY);
                            }}
                            disabled={!isBackgroundSelected}
                        >
                            <span className="ScreenSelect_Btn_Text">Next</span>
                            <div className="ScreenSelect_Btn_Circle">{SVG_BTN}</div>
                        </button>
                    </div>
                </div>
            );
        }
    }

    return <div />;
}

BackgroundView.defaultProps = {
    enableGreenScreen: PropTypes.bool.isRequired,
    listLengthBackground: PropTypes.number.isRequired,
    orientation: PropTypes.string.isRequired,
    setArtworkGreenScreenBackground: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

BackgroundView.propTypes = {
    enableGreenScreen: PropTypes.bool,
    listLengthBackground: PropTypes.number,
    orientation: PropTypes.string,
    setArtworkGreenScreenBackground: PropTypes.func,
    history: PropTypes.shape(historyPropTypes)
};
