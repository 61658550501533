import * as SparkMD5 from "spark-md5";

const DEVICE_UNKNOWN = -1;
const DEVICE_IPHONE = 1;
const DEVICE_ANDROID = 2;
const DEVICE_WIN_PHONE = 3;

const SUPPORTED_BROWSERS = [
    ["Edg", 79],
    ["Firefox", 25],
    ["Chrome", 49],
    ["Version", 14]
];
const SUPPORTED_IOS = 14.6;
const SUPPORTED_ANDROID = 8;

export const computeChecksumMd5 = (file) => new Promise((resolve, reject) => 
{
    const chunkSize = 2097152;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let cursor = 0;

    fileReader.onerror = () => reject(new Error("MD5 computation failed - error reading the file"));

    function processChunk(chunkStart) 
    {
        const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
    }

    fileReader.onload = (e) => 
    {
        spark.append(e.target.result);
        cursor += chunkSize;
        (cursor < file.size) ? processChunk(cursor) : resolve(spark.end(false)); // hexdigest
        // (cursor < file.size) ? processChunk(cursor) : resolve(btoa(spark.end(true))); // base64 encoded
    };

    processChunk(0);
});

export const checkIsMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const getVersionAndroid = () =>
{
    const navigatorAgent = navigator.userAgent.toLowerCase();
    const match = navigatorAgent.match(/android\s([0-9]*)/i);

    return match ? match[1] : undefined;
};

const getVersionIOS = () =>
{
    if (/iP(hone|od|ad)/.test(navigator.platform))
    {
        const versionFull = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        const versionString = versionFull[0].split(" ")[1].replace("_", ".").replace("_", ".");

        return versionString && parseFloat(versionString);
    }

    return 0;
};

const isWinPhone = (userAgent) => (/windows phone/i.test(userAgent));

const isAndroid = (userAgent) => (/android/i.test(userAgent));

const isiOS = (userAgent) => (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);

const getMobileOperatingSystem = () =>
{
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (isWinPhone(userAgent))
    {
        return DEVICE_WIN_PHONE;
    }

    if (isAndroid(userAgent))
    {
        return DEVICE_ANDROID;
    }

    if (isiOS(userAgent))
    {
        return DEVICE_IPHONE;
    }

    return DEVICE_UNKNOWN;
};

const isSupportedMobile = () =>
{
    const myDevice = getMobileOperatingSystem();

    if (myDevice === DEVICE_IPHONE)
    {
        const version = getVersionIOS();

        if (version >= SUPPORTED_IOS)
        {
            return true;
        }

        const alertText = `Video requires iOS ${SUPPORTED_IOS} or greater, please upgrade your device`;
        alert(alertText);
    }
    else if (myDevice === DEVICE_ANDROID)
    {
        const version = getVersionAndroid();

        if (version >= SUPPORTED_ANDROID)
        {
            return true;
        }

        const alertText = `Video requires Android ${SUPPORTED_ANDROID} or greater, please upgrade your device`;
        alert(alertText);
    }

    return false;
};

const isSupportedPC = () =>
{
    const navigatorVersion = navigator.appVersion;
    const navigatorAgent = navigator.userAgent;
    const { length } = Object.keys(SUPPORTED_BROWSERS);
    let fullVersion = `${parseFloat(navigatorVersion)}`;
    let majorVersion;
    let verOffset;

    for (let i = 0; i < length; i++)
    {
        if ((verOffset = navigatorAgent.indexOf(SUPPORTED_BROWSERS[i][0])) !== -1)
        {
            fullVersion = navigatorAgent.substring(verOffset + SUPPORTED_BROWSERS[i][0].length + 1);
            majorVersion = parseInt(`${fullVersion}`, 10);

            if (majorVersion >= SUPPORTED_BROWSERS[i][1])
            {
                return true;
            }
            alert("Video requires iOS 14.6 or greater, please upgrade your device.");
        }
    }

    return false;
};

export const isBrowserSupported = () =>
{
    const isMobile = checkIsMobile();

    return isMobile ? isSupportedMobile() : isSupportedPC();
};
