import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Button, ListGroup, ListGroupItem, Card } from "react-bootstrap";
import { MdCameraFront, MdCameraRear } from "react-icons/all";
import {
    backgroundChooserSvg,
    cameraActivitySvgGif,
    frameChooserSvg,
    faceMeshChooserSvg,
    shutterSvg,
    recordSvg,
    stopSvg
} from "../../../assets/svg";
import { CLASS_NONE, CLASS_FLEX } from "../../../assets/constants";

const CameraBtnsView = ({ facingMode, contentType, openChooser, isProcessPhotosReady, testFlag, isVideoCaptureStarted,
    campaign, startProcessListPhotos, handleSwap, setChooserModalBackgroundOpen, enableFaceMesh, setShowCountDownOn,
    setChooserModalFrameOpen, setChooserModalFaceOpen, handleDone, stopRecording, facingModeCount }) =>
{
    if (!campaign)
    {
        return null;
    }
    
    const draggableDisplay = openChooser ? CLASS_NONE : "";
    const draggableClass = `CameraActivity_Draggable react-draggable ${draggableDisplay}`;
    const mdCamera = (facingMode === "environment") ?
        <MdCameraFront className="cameraPreviewIcons" /> :
        <MdCameraRear className="cameraPreviewIcons" />;

    const photoDisplay = campaign.is_photo_enabled && (contentType === "still") ? CLASS_FLEX : CLASS_NONE;
    const photoClass = `CameraActivity_BtnPhoto ${photoDisplay}`;
    const gifDisplay = campaign.is_gif_enabled && (contentType === "gif") ? CLASS_FLEX : CLASS_NONE;
    const gifClass = `CameraActivity_BtnPhoto ${gifDisplay}`;
    const videoDisplay = campaign.is_video_enabled && (contentType === "video") ? CLASS_FLEX : CLASS_NONE;
    const videoClass = `CameraActivity_BtnPhoto ${videoDisplay}`;

    const backgroundClass = (campaign.green_screen_settings.backgrounds.length > 1) ? CLASS_FLEX : CLASS_NONE;
    const backgroundChooser = (campaign.green_screen_settings.backgrounds.length > 1) ? CLASS_FLEX : CLASS_NONE;
    const backgroundChooserClass = `CameraActivity_BtnPhoto ${backgroundChooser}}`;
    const frameChooser = (campaign.photo_settings[0].branding.frames.length > 1) ? CLASS_FLEX : CLASS_NONE;
    const frameChooserClass = `CameraActivity_BtnPhoto ${frameChooser}`;
    const faceMeshChooser = (enableFaceMesh && campaign?.facetextures.length > 1) ? CLASS_FLEX : CLASS_NONE;
    const faceMeshChooserClass = `CameraActivity_BtnPhoto ${faceMeshChooser}`;

    const videoBtnSvg = isVideoCaptureStarted ? stopSvg : recordSvg;
    const isButtonDisabled = isVideoCaptureStarted;
    const videoOnClick = isVideoCaptureStarted ? stopRecording : setShowCountDownOn;

    const facingModeDisplay = facingModeCount === 2 ? CLASS_FLEX : CLASS_NONE;
    const facingModeClass = `CameraActivity_BtnPhoto ${facingModeDisplay}`;

    const btnDisabledClass = isButtonDisabled ? "CameraActivity_Btn_Disabled" : "";
    const btnCloseClass = `CameraActivity_BtnClose ${btnDisabledClass}`;

    return (
        <div className={draggableClass}>
            <Card>
                <strong className="cursor CameraActivity_Strong">
                    &nbsp;
                </strong>
                <Box component="div">
                    <ListGroup horizontal className="CameraActivity_NoPadding">
                        <ListGroupItem variant="flush" className={facingModeClass}>
                            <button
                                type="button"
                                className="CameraActivity_Btn"
                                onClick={handleSwap}
                                disabled={isButtonDisabled}
                            >
                                {mdCamera}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={photoClass}>
                            <button
                                type="button"
                                className={photoClass}
                                onClick={setShowCountDownOn}
                                disabled={isButtonDisabled}
                            >
                                {shutterSvg}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={videoClass}>
                            <button
                                type="button"
                                className={videoClass}
                                onClick={videoOnClick}
                            >
                                {videoBtnSvg}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={gifClass}>
                            <button
                                type="button"
                                className={gifClass}
                                onClick={setShowCountDownOn}
                                disabled={isButtonDisabled}
                            >
                                {cameraActivitySvgGif}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={backgroundClass}>
                            <button
                                type="button"
                                onClick={setChooserModalBackgroundOpen}
                                className={backgroundChooserClass}
                                disabled={isButtonDisabled}
                            >
                                {backgroundChooserSvg}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={frameChooserClass}>
                            <button
                                type="button"
                                onClick={setChooserModalFrameOpen}
                                className={frameChooserClass}
                                disabled={isButtonDisabled}
                            >
                                {frameChooserSvg}
                            </button>
                        </ListGroupItem>
                        <ListGroupItem variant="flush" className={faceMeshChooserClass}>
                            <button
                                type="button"
                                onClick={setChooserModalFaceOpen}
                                className={faceMeshChooserClass}
                                disabled={isButtonDisabled}
                            >
                                {faceMeshChooserSvg}
                            </button>
                        </ListGroupItem>
                        {(isProcessPhotosReady && testFlag) && (
                            <ListGroupItem variant="flush" className="CameraActivity_Flex">
                                <button
                                    type="button"
                                    className="ui button CameraActivity_BtnClose"
                                    onClick={startProcessListPhotos}
                                    disabled={isButtonDisabled}
                                >
                                    Process Photos
                                </button>
                            </ListGroupItem>
                        )}
                        <ListGroupItem variant="flush" className="CameraActivity_Flex">
                            <Button
                                className={`cameraPreviewIcons ${btnCloseClass}`}
                                onClick={handleDone}
                                variant="outline-danger"
                                disabled={isButtonDisabled}
                            >
                                X
                            </Button>
                        </ListGroupItem>
                    </ListGroup>
                </Box>
            </Card>
        </div>
    );
};

CameraBtnsView.defaultProps = {
    facingMode: "user",
    contentType: PropTypes.string.isRequired,
    openChooser: false,
    isProcessPhotosReady: false,
    testFlag: false,
    isVideoCaptureStarted: false,
    facingModeCount: PropTypes.number.isRequired,
    campaign: null,
    startProcessListPhotos: PropTypes.func.isRequired,
    handleSwap: PropTypes.func.isRequired,
    setChooserModalBackgroundOpen: PropTypes.func.isRequired,
    setChooserModalFaceOpen: PropTypes.func.isRequired,
    setChooserModalFrameOpen: PropTypes.func.isRequired,
    handleDone: PropTypes.func.isRequired,
    stopRecording: PropTypes.func.isRequired,
    setShowCountDownOn: PropTypes.func.isRequired
};

CameraBtnsView.propTypes = {
    facingMode: PropTypes.string,
    contentType: PropTypes.string,
    openChooser: PropTypes.bool,
    isProcessPhotosReady: PropTypes.bool,
    testFlag: PropTypes.bool,
    isVideoCaptureStarted: PropTypes.bool,
    facingModeCount: PropTypes.number,
    campaign: PropTypes.instanceOf(Object),
    startProcessListPhotos: PropTypes.func,
    handleSwap: PropTypes.func,
    setChooserModalBackgroundOpen: PropTypes.func,
    setChooserModalFaceOpen: PropTypes.func,
    setChooserModalFrameOpen: PropTypes.func,
    handleDone: PropTypes.func,
    stopRecording: PropTypes.func,
    setShowCountDownOn: PropTypes.func
};

export default CameraBtnsView;
