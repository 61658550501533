import React from "react";

export const SVG_BTN = (
    <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#fff"
        style={{ marginLeft: "2px" }}
    >
        <path
            d="M1 1L5 5L1 9"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

export const SVG_STILL = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#556074"
        viewBox="0 0 33 32"
    >
        <path d="M.499 0v32h32V0H.5zm30.125 30.125H2.374V1.875h28.25v28.25z" />
        <path d="M28.351 4.25H4.646v19.062H28.35V4.25zm-9.316 8.607a1.692 1.692 0 113.385 0 1.692 1.692 0 01-3.385
        0zm2.918 3.566a4.526 4.526 0 014.526 4.526v.489h-9.042v-.49a4.526 4.526 0 014.525-4.525h-.01zM6.52
        6.125h19.958v10.298a6.402 6.402 0 00-2.758-1.628 3.566 3.566 0 10-5.271.803 6.446 6.446 0 00-1.95 2.014
        6.447 6.447 0 00-1.949-2.015 3.567 3.567 0 10-5.27-.803c-1.042.3-1.991.86-2.758 1.628V6.125h-.002zm5.749
        8.423a1.692 1.692 0 11-.003-3.384 1.692 1.692 0 01.004 3.384h-.001zm-5.75 6.394a4.526 4.526 0 119.041
        0v.49h-9.04v-.49zM12.271 25.75h8.456v1.875h-8.456V25.75z"
        />
    </svg>
);

export const SVG_GIF = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#556074"
        viewBox="0 0 35 31"
    >
        <path d="M17.549 10.723a3.534 3.534 0 100 7.067 3.534 3.534 0 000-7.067zm0 5.385a1.851
        1.851 0 11.092-3.7 1.851 1.851 0 01-.092 3.7z"
        />
        <path d="M31.65 2.644L8.67.03A3.156 3.156 0 006.19.747a3.198 3.198 0 00-1.22 2.187l-.421 3.452H3.243A3.381
        3.381 0 00.002 9.881V27.09a3.198 3.198 0 003.107 3.28h23.234a3.453 3.453 0 003.535-3.282v-.673a4.208 4.208
        0 001.598-.673 3.493 3.493 0 001.22-2.272l1.936-17.083a3.409 3.409 0 00-2.982-3.743zM28.197 27.09a1.771
        1.771 0 01-1.854 1.596h-23.1a1.515 1.515 0 01-1.558-1.47v-3.24l6.522-4.797a2.02 2.02 0 012.61.127l4.585
        4.039a3.955 3.955 0 002.483.92 3.83 3.83 0 002.02-.546l8.285-4.797v8.163l.007.005zm0-10.14l-9.173
        5.343a2.146 2.146 0 01-2.482-.21l-4.629-4.082a3.746 3.746 0 00-4.67-.168l-5.554 4.039V9.88a1.699
        1.699 0 011.557-1.809h23.097a1.936 1.936 0 011.854 1.81v7.069zm4.757-10.788v.017L30.97 23.26a1.43
        1.43 0 01-.547 1.137c-.168.168-.546.252-.546.337V9.88a3.618 3.618 0
        00-3.535-3.493H6.23l.379-3.282c.082-.425.304-.81.63-1.094.37-.256.818-.375 1.266-.337l22.932
        2.647A1.682 1.682 0 0132.95 6.16l.004.002z"
        />
    </svg>
);

export const SVG_VIDEO = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#556074"
        viewBox="0 0 200 200"
    >
        <path d="M182.4,29H18.2c-8.4,0-15,6.8-15,15v112.9c0,8.4,6.8,15,15,15h164.2c8.4,0,15-6.8,15-15V44 C197.4,35.8,
        190.6,29,182.4,29z M187.4,157.1c0,2.8-2.2,5-5,5H18.2c-2.8,0-5-2.2-5-5V44.2c0-2.8,2.2-5,5-5h164.2c2.8,0,5,2.2,5,5
        V157.1L187.4,157.1z"
        />
        <path d="M169.6,140.3h-35.8v-1.4c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5v1.4H30.5c-2.8,0-5,2.2-5,5s2.2,5,5,5h93.3v1.2
        c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5v-1h35.8c2.8,0,5-2.2,5-5C175,142.5,172.6,140.3,169.6,140.3z"
        />
        <path d="M100.3,131.1c21.2,0,38.4-17.4,38.4-38.4s-17.4-38.4-38.4-38.4S61.9,71.6,61.9,92.6S79.1,131.1,100.3,
        131.1z M100.3,62c16.8,0,30.4,13.6,30.4,30.4s-13.6,30.4-30.4,30.4s-30.4-13.6-30.4-30.4S83.3,62,100.3,62z"
        />
        <path d="M91.7,114.9c1.2,0,2.4-0.4,3.6-1.2l24.4-16c2-1.2,3-3.2,
        3-5.2c0-2-1-4-3-5.2l-24.4-16c-1.2-0.6-2.4-1.2-3.6-1.2
        c-2.8,0-5.6,2-5.6,6v32.8C86.3,112.9,89.1,114.9,91.7,114.9z M94.3,80.2l18.6,12.2l-18.6,12.2V80.2z"
        />
    </svg>
);
