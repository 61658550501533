import { connect } from "react-redux";
import {
    selectCampaign,
    selectImageWorker,
    selectVideoWorker,
    selectOrientation
} from "../../state/selectors";
import KioskStartScreenView from "./KioskStartScreen";

const mapStateToProps = (state) => ({
    campaign: selectCampaign(state),
    orientation: selectOrientation(state) || "",
    lockOrientation: selectCampaign(state)?.force_orientation || "",
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state)
});

const mapDispatchToProps = () => ({
});

const KioskStartScreen = connect(
    mapStateToProps,
    mapDispatchToProps
)(KioskStartScreenView);

export default KioskStartScreen;
