import { Component } from "react";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";

const OPTIONS = {
    modelSelection: 1,
    effect: "background",
    selfieMode: false
};

class SelfieSegmentationModel extends Component
{
    componentDidMount = async () =>
    {
        this.selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => `/selfie_segmentation/${file}`
        });

        this.selfieSegmentation.setOptions(OPTIONS);
        this.selfieSegmentation.onResults(this.onResults);

        requestAnimationFrame(this.processSelfieSegmentation);
    }

    processSelfieSegmentation = async () =>
    {
        const { drawCanvasRef } = this.props;

        if (drawCanvasRef.current)
        {
            await this.selfieSegmentation.send({ image: drawCanvasRef.current });
        }
    }

    componentWillUnmount = () =>
    {
        this.selfieSegmentation.close(); // clean up
    }

    onResults = (results) =>
    {
        const { callback } = this.props;

        results && callback(results.segmentationMask);

        requestAnimationFrame(this.processSelfieSegmentation);
    };

    render()
    {
        return null;
    }
}

export { SelfieSegmentationModel };
