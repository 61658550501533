import { connect } from "react-redux";
import {
    selectCampaign,
    selectCampaignID,
    selectImageWorker,
    selectVideoWorker,
    selectIsProcessPhotosReady,
    selectIsProcessPhotosStarted,
    selectJpgList,
    selectJpgListLength,
    selectLastSurveyId,
    selectTestFlag,
    selectArtworkAppBackground,
    selectContentType
} from "../../state/selectors";
import {
    addImage,
    addVideo,
    setIsProcessPhotosReady,
    setIsProcessPhotosStarted
} from "../../state/actions";
import CameraActivityView from "./CameraActivity";

const mapStateToProps = (state) => ({
    campaignID: selectCampaignID(state),
    campaign: selectCampaign(state),
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state),
    surveyID: selectLastSurveyId(state),
    jpgList: selectJpgList(state),
    isProcessPhotosStarted: selectIsProcessPhotosStarted(state),
    isProcessPhotosReady: selectIsProcessPhotosReady(state),
    jpgListLength: selectJpgListLength(state),
    testFlag: selectTestFlag(state),
    artWorkAppBackgroundUrl: selectArtworkAppBackground(state).url,
    enableFaceMesh: selectCampaign(state).is_facetexture_enabled,
    enableGreenScreen: selectCampaign(state).is_green_screen_enabled,
    contentType: selectContentType(state)
});

const mapDispatchToProps = (dispatch) => ({
    addImage: (value) => dispatch(addImage(value)),
    addVideo: (video) => dispatch(addVideo(video)),
    setIsProcessPhotosStarted: (value) => dispatch(setIsProcessPhotosStarted(value)),
    setIsProcessPhotosReady: (value) => dispatch(setIsProcessPhotosReady(value))
});

const CameraActivity = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraActivityView);

export default CameraActivity;
