import { connect } from "react-redux";
import GreenScreenBackgroundView from "./GreenScreenBackground";
import { selectArtworkGreenScreenBackground, selectCampaign, selectOrientation} from "../../../../state/selectors";
import { setArtworkGreenScreenBackground } from "../../../../state/actions";

const mapStateToProps = (state) => 
{    
    const campaign = selectCampaign(state);
    const listLengthBackground = campaign.green_screen_settings.backgrounds.length;

    return ({
        orientation: selectOrientation(state),
        listLengthBackground: listLengthBackground,
        artWorkGreenScreenBackgroundIndex: selectArtworkGreenScreenBackground(state)?.index || 0
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkGreenScreenBackground: (url, index) => dispatch(setArtworkGreenScreenBackground(url, index))
});

const GreenScreenBackground = connect(
    mapStateToProps,
    mapDispatchToProps
)(GreenScreenBackgroundView);

export default GreenScreenBackground;
