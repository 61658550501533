import React, { useState, useEffect } from "react";
import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import TypeBox from "./TypeBox/TypeBox";
import { Header } from "../Header/Header";
import { SVG_BTN, SVG_STILL, SVG_GIF, SVG_VIDEO } from "./svg";
import { CONTENT_TYPE_STILL, CONTENT_TYPE_VIDEO, CONTENT_TYPE_GIF } from "../../../assets/constants";
import "../style.css";

export default function ContentTypeView({ isLeadGenOn, isPhotoEnabled, isGifEnabled, isVideoEnabled,
    history, setContentType })
{
    const [type, setType] = useState("");

    const processNextPath = (newType) =>
    {
        const nextHistory = isLeadGenOn ? "/LeadGenActivity" : "/Background";

        setContentType(newType);
        history.push(nextHistory);
    };

    useEffect(() =>
    {
        let newType = type;

        if ((isPhotoEnabled + isGifEnabled + isVideoEnabled) <= 1)
        {
            if (isPhotoEnabled)
            {
                newType = CONTENT_TYPE_STILL;
            }

            if (isVideoEnabled)
            {
                newType = CONTENT_TYPE_VIDEO;
            }

            if (isGifEnabled)
            {
                newType = CONTENT_TYPE_GIF;
            }

            processNextPath(newType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div role="main" className="ScreenSelect_Container">
            <Header />
            <span className="ScreenSelect_Header">Select Your Experience:</span>
            <span className="ScreenSelect_Choose">Choose below!</span>
            <TypeBox
                svg={SVG_STILL}
                name="Still"
                description="Captures a single photo"
                isActive={type === CONTENT_TYPE_STILL}
                isEnabled={isPhotoEnabled}
                onClickCallBack={setType}
            />
            <TypeBox
                svg={SVG_GIF}
                name="GIF"
                description="A set of photos will be taken and converted into GIF"
                isActive={type === CONTENT_TYPE_GIF}
                isEnabled={isGifEnabled}
                onClickCallBack={setType}
            />
            <TypeBox
                svg={SVG_VIDEO}
                name="Video"
                description="Captures a short video"
                isActive={type === CONTENT_TYPE_VIDEO}
                isEnabled={isVideoEnabled}
                onClickCallBack={setType}
            />
            <button
                type="button"
                className="ScreenSelect_Btn"
                onClick={(e) =>
                {
                    e.preventDefault();
                    processNextPath(type);
                }}
                disabled={!type}
            >
                <span className="ScreenSelect_Btn_Text">Next</span>
                <div className="ScreenSelect_Btn_Circle">{SVG_BTN}</div>
            </button>
        </div>
    );
}

ContentTypeView.defaultProps = {
    isLeadGenOn: PropTypes.bool.isRequired,
    isPhotoEnabled: PropTypes.bool.isRequired,
    isGifEnabled: PropTypes.bool.isRequired,
    isVideoEnabled: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    setContentType: PropTypes.func.isRequired
};

ContentTypeView.propTypes = {
    isLeadGenOn: PropTypes.bool,
    isPhotoEnabled: PropTypes.bool,
    isGifEnabled: PropTypes.bool,
    isVideoEnabled: PropTypes.bool,
    history: PropTypes.shape(historyPropTypes),
    setContentType: PropTypes.func
};
