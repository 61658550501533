/* eslint import/no-webpack-loader-syntax: off */
import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import Worker from "worker-loader!../Workers/UploadWorker";
import Routes from "../../Routes";
import HeaderBar from "../HeaderBar";
import AppInfo from "../AppInfo";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "./style.css";

const history = createBrowserHistory();
history.listen(({ pathname, hash }) =>
{
    ReactGA.set({ page: (pathname + hash) }); // Update the user's current page
    ReactGA.pageview(pathname + hash); // Record a pageview for the given page
});

export default class AppView extends Component
{
    componentDidMount()
    {
        const { setTestFlag, setImageWorker, setVideoWorker } = this.props;
        const { REACT_APP_TESTFLAG: testFlag } = process.env;
        const uploadWorker = new Worker();
        const uploadVideoWorker = new Worker();
        const flag = (testFlag?.localeCompare("true") === 0);

        setTestFlag(flag);
        setImageWorker(uploadWorker);
        setVideoWorker(uploadVideoWorker);

        uploadWorker.addEventListener("message", this.receiveProgress);
        uploadVideoWorker.addEventListener("message", this.receiveProgress);
    }

    receiveProgress = (msg) =>
    {
        const { messageType, uploadError, ID, current, total, pageUrl, url, isVideo } = msg.data;
        const { setUploadProgress, setUploadError, setImageURL, setVideoURL } = this.props;

        // console.log("Got message", msg);
        // console.log(JSON.stringify(msg.data));
        // console.log(imageID + ": " + (current * 100 / total));

        const setUrl = isVideo ? setVideoURL : setImageURL;

        switch (messageType)
        {
            case "upload_progress":
                if (!uploadError || uploadError === "")
                {
                    setUploadProgress(ID, current, total);
                }
                else
                {
                    setUploadError(ID, uploadError);
                }
                break;

            case "upload_complete":
                setUrl(ID, pageUrl, url);
                break;

            default:
                break;
        }
    };

    render()
    {
        return (
            <HashRouter history={{ history }}>
                <div className="container-fluid mainContainer">
                    <HeaderBar />
                    <main className="App_Main">
                        <Routes />
                    </main>
                    <AppInfo />
                </div>
            </HashRouter>
        );
    }
}

AppView.defaultProps = {
    setImageWorker: PropTypes.func.isRequired,
    setVideoWorker: PropTypes.func.isRequired,
    setTestFlag: PropTypes.func.isRequired,
    setUploadProgress: PropTypes.func.isRequired,
    setUploadError: PropTypes.func.isRequired,
    setImageURL: PropTypes.func.isRequired,
    setVideoURL: PropTypes.func.isRequired
};

AppView.propTypes = {
    setImageWorker: PropTypes.func,
    setVideoWorker: PropTypes.func,
    setTestFlag: PropTypes.func,
    setUploadProgress: PropTypes.func,
    setUploadError: PropTypes.func,
    setImageURL: PropTypes.func,
    setVideoURL: PropTypes.func
};
