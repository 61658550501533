import PropTypes from "prop-types";
import { arrayBufferObjectToBlob, getArtwork } from "../../../../db";

export default function AppBackgroundView({ orientation, setArtworkAppBackground, artWorkAppBackgroundIndex })
{
    getArtwork(`app_background_${orientation}`).then((result) =>
    {
        if (result != null)
        {
            const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data, result.mimeType));
            setArtworkAppBackground(url, artWorkAppBackgroundIndex);
        }
    });

    return null;
}

AppBackgroundView.defaultProps = {
    orientation: PropTypes.string.isRequired,
    setArtworkAppBackground: PropTypes.func.isRequired,
    artWorkAppBackgroundIndex: PropTypes.number.isRequired
};

AppBackgroundView.propTypes = {
    orientation: PropTypes.string,
    setArtworkAppBackground: PropTypes.func,
    artWorkAppBackgroundIndex: PropTypes.number
};
