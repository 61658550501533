/* eslint max-len: ["error", { "code": 2000 }] */
import React from "react";

export const instagramSvg = (
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        width="64"
        height="64"
    >
        <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="130.2144"
            y1="2079.4189"
            x2="888.6135"
            y2="1321.0197"
            gradientTransform="matrix(1 0 0 -1 0 2208)"
        >
            <stop offset="3.902440e-02" style={{ stopColor: "#8549E4" }} />
            <stop offset="0.1591" style={{ stopColor: "#A846BF" }} />
            <stop offset="0.311" style={{ stopColor: "#CD4498" }} />
            <stop offset="0.4505" style={{ stopColor: "#E9427B" }} />
            <stop offset="0.5717" style={{ stopColor: "#F9416A" }} />
            <stop offset="0.661" style={{ stopColor: "#FF4164" }} />
            <stop offset="0.7213" style={{ stopColor: "#FF525D" }} />
            <stop offset="0.8406" style={{ stopColor: "#FF7E4B" }} />
            <stop offset="1" style={{ stopColor: "#FFC12F" }} />
        </linearGradient>
        <path
            fill="url(#SVGID_1_)"
            d="M498.6,18c629.6,10.7,629.5,947.3,0,958C-131,965.2-130.8,28.7,498.6,18z"
        />
        <path
            fill="white"
            d="M498.6,277.1c117.7,1,211.8-14.8,218.3,111.5c-0.7,57,10.2,213.3-9.2,266.7c-38.5,80-109.7,57-209.1,61.7
        c-99.3-4.5-170.5,18.3-208.5-61.8c-18.9-52.5-9.3-210.5-9.2-266.7c6.6-126.1,100.2-109.6,217.8-110.9 M498.6,229.3
        c-123.4,3.3-202.1-16.2-254.6,93c-21.2,74.1-11,72.2-14.1,175.7c2.7,123.3-14.2,201.2,94,253.6c74.1,21.2,72.2,11,175.7,14.1
        c123.4-3.3,202.1,16.2,254.6-93c21.2-74.1,11-72.2,14.1-175.7c-3.3-123.4,16.2-202.1-93-254.6c-74.1-21.2-72.2-11-175.7-14.1
        L498.6,229.3z"
        />
        <path
            fill="white"
            d="M498.6,359.3c-181,3.1-181,272.4,0,275.5C679.7,631.6,679.6,362.3,498.6,359.3L498.6,359.3z M498.6,586.2
        c-49.4,0-89.4-40-89.4-89.4c2-117.5,176.8-117.5,178.8,0l0,0C588,546.3,548,586.2,498.6,586.2z"
        />
        <path
            fill="white"
            d="M641.8,321.6c42.3,0.7,42.3,63.7,0,64.5C599.5,385.4,599.5,322.3,641.8,321.6z"
        />
    </svg>
);

export const downloadSvg = (
    <svg
        viewBox="0 0 64 64"
        width="64"
        height="64"
    >
        <circle cx="32" cy="32" r="31" fill="#b6862e" />
        <path
            fill="white"
            d="M29.7,17.6h4.5c0.7,0,1.3,0.6,1.3,1.3v9.4h4.9c1,0,1.5,1.2,0.8,1.9l-8.5,8.5c-0.4,0.4-1.1,0.4-1.5,0l-8.5-8.5
    c-0.7-0.7-0.2-1.9,0.8-1.9h4.9v-9.4C28.3,18.2,28.9,17.6,29.7,17.6z M46.3,38.7v6.3c0,0.7-0.6,1.3-1.3,1.3h-26
    c-0.7,0-1.3-0.6-1.3-1.3v-6.3c0-0.7,0.6-1.3,1.3-1.3h8.2l2.8,2.8c1.1,1.1,2.9,1.1,4.1,0l2.8-2.8h8.2C45.7,37.3,46.3,37.9,46.3,38.7z
    M39.3,43.6c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1C38.8,44.7,39.3,44.2,39.3,43.6z M42.9,43.6
    c0-0.6-0.5-1.1-1.1-1.1c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1C42.4,44.7,42.9,44.2,42.9,43.6z"
        />
    </svg>
);

export const cameraActivitySvg = (text = "PHOTO") => (
    <svg viewBox="0 0 64 64" className="cameraPreviewIcons">
        <circle cx="32" cy="32" r="31" fill="#fffff" />
        <text
            x="50%"
            y="50%"
            fill="#fffff"
            className="small"
            style={{
                fontSize: "16",
                dominantBaseline: "central",
                fill: "white",
                textLength: 64,
                textAnchor: "middle",
                fontWeight: "bold"
            }}
        >
            {text}
        </text>
    </svg>
);

export const cameraActivitySvgGif = (
    <svg viewBox="0 0 64 64" className="cameraPreviewIcons">
        <circle cx="32" cy="32" r="31" fill="#fffff" />
        <circle
            cx="32"
            cy="32"
            r="26"
            stroke="#fff"
            strokeWidth="3"
            strokeDasharray="6"
            strokeLinecap="square"
            fill="transparent"
        />
        <text
            x="50%"
            y="50%"
            fill="#fffff"
            className="small"
            style={{
                fontSize: "16",
                dominantBaseline: "central",
                fill: "white",
                textLength: 32,
                textAnchor: "middle",
                fontWeight: "bold"
            }}
        >
            GIF
        </text>
    </svg>
);

export const backgroundChooserSvg = (
    <svg viewBox="0 0 64 64" className="cameraPreviewIcons">
        <circle cx="32" cy="32" r="31" fill="#fffff" />
        <text
            x="50%"
            y="50%"
            fill="#fffff"
            className="small"
            style={{
                fontSize: "16",
                dominantBaseline: "central",
                fill: "white",
                textLength: 64,
                textAnchor: "middle",
                fontWeight: "bold"
            }}
        >
            BG
        </text>
    </svg>
);

export const frameChooserSvg = (
    <svg viewBox="0 0 64 64" className="cameraPreviewIcons">
        <circle cx="32" cy="32" r="31" fill="#fffff" />
        <text
            x="50%"
            y="50%"
            fill="#fffff"
            className="small"
            style={{
                fontSize: "16",
                dominantBaseline: "central",
                fill: "white",
                textLength: 64,
                textAnchor: "middle",
                fontWeight: "bold"
            }}
        >
            FRAME
        </text>
    </svg>
);

export const faceMeshChooserSvg = (
    <svg viewBox="0 0 64 64" className="cameraPreviewIcons">
        <circle cx="32" cy="32" r="31" fill="#fffff" />
        <text
            x="50%"
            y="50%"
            fill="#fffff"
            className="small"
            style={{
                fontSize: "16",
                dominantBaseline: "central",
                fill: "white",
                textLength: 64,
                textAnchor: "middle",
                fontWeight: "bold"
            }}
        >
            FACE
        </text>
    </svg>
);

export const shutterSvg = (
    <svg viewBox="0 0 47 47" className="cameraPreviewIcons">
        <path
            d="M39.1,9.6l-0.5-0.5c-0.7-0.6-1.3-1.1-1.8-1.6C35.3,6.1,33.4,5,33.4,5c-1.9-1-3.9-1.7-6.1-2.1
    c-0.9-0.2-1.8-0.3-1.8-0.3c-0.5-0.1-1.9-0.1-2.4,0c0,0-0.8,0-1.3,0.1l-1.5,0.2l11.4,19.8L39.1,9.6z"
        />
        <path
            d="M43.3,29.9l0.2-0.7c0.2-0.9,0.4-1.7,0.5-2.4c0.4-2,0.3-4.2,0.3-4.2c-0.1-2.2-0.5-4.2-1.2-6.3
    c-0.3-0.8-0.7-1.7-0.7-1.7c-0.2-0.5-0.9-1.7-1.2-2.1c0,0-0.4-0.7-0.7-1.1l-0.9-1.2L28.3,30L43.3,29.9z"
        />
        <path
            d="M27.8,43.6l0.7-0.2c0.9-0.3,1.6-0.5,2.3-0.8c1.9-0.6,3.8-1.8,3.8-1.8c1.8-1.2,3.4-2.5,4.9-4.2
    c0.6-0.7,1.1-1.5,1.1-1.5c0.3-0.4,1-1.6,1.2-2c0,0,0.4-0.7,0.6-1.2l0.6-1.4l-22.8,0L27.8,43.6z"
        />
        <path
            d="M8,37.5L8.5,38c0.7,0.6,1.3,1.1,1.8,1.6c1.5,1.3,3.5,2.4,3.5,2.4c1.9,1,3.9,1.7,6.1,2.1
    c0.9,0.2,1.8,0.2,1.8,0.2c0.5,0,1.9,0,2.4,0c0,0,0.8,0,1.3-0.1l1.5-0.2L15.3,24.3L8,37.5z"
        />
        <path
            d="M3.8,17l-0.2,0.7c-0.2,0.9-0.4,1.7-0.5,2.4c-0.4,2-0.4,4.2-0.4,4.2c0.1,2.2,0.5,4.2,1.2,6.3
    c0.3,0.8,0.7,1.7,0.7,1.7c0.2,0.5,0.9,1.7,1.1,2.1c0,0,0.4,0.7,0.7,1.1l0.9,1.2l11.4-19.7L3.8,17z"
        />
        <path
            d="M19.2,3.1l-0.7,0.2c-0.9,0.3-1.6,0.5-2.3,0.8c-1.9,0.7-3.8,1.9-3.8,1.9C10.6,7.1,9,8.5,7.5,10.2
    c-0.6,0.7-1.1,1.5-1.1,1.5c-0.3,0.4-1,1.6-1.2,2c0,0-0.4,0.7-0.6,1.2l-0.6,1.4L26.9,16L19.2,3.1z"
        />
    </svg>
);

export const recordSvg = (
    <svg viewBox="0 0 47 47" className="cameraPreviewIcons">
        <circle
            fill="white"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="23.5"
            cy="23.5"
            r="20"
        />
        <circle
            fill="#D42300"
            cx="23.7"
            cy="23.3"
            r="9.4"
        />
    </svg>
);

export const stopSvg = (
    <svg viewBox="0 0 47 47" className="cameraPreviewIcons">
        <circle
            fill="white"
            stroke="black"
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="23.5"
            cy="23.5"
            r="20"
        />
        <rect
            fill="#D42300"
            x="15.9"
            y="15.9"
            width="15"
            height="15"
        />
    </svg>
);
