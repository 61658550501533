import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../../db";
import { FrameChooser } from "../../FrameChooser/FrameChooser";

export default function GreenScreenBackgroundView({ openChooserBackground, setChooserModalBackgroundClosed,
    orientation, listLengthBackground, setArtworkGreenScreenBackground, artWorkGreenScreenBackgroundIndex })
{
    const [galleryBackground, setGalleryBackground] = useState([]);
    const [listBackground, setListBackground] = useState([]);
    const [indexBackground, setIndexBackground] = useState(artWorkGreenScreenBackgroundIndex);

    useEffect(() =>
    {
        getArtwork(`greenscreen_background_${orientation}_${indexBackground}`).then((result) =>
        {
            if (result?.data != null)
            {
                const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data));
                setArtworkGreenScreenBackground(url, indexBackground);
            }
        });
    }, [orientation, indexBackground, setArtworkGreenScreenBackground]);

    if (listLengthBackground && openChooserBackground)
    {
        if (listBackground.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("greenscreen").each((art) =>
            {
                if (art.member.endsWith(orientation))
                {
                    artworkList.push({
                        original: art.url,
                        thumbnail: art.url,
                        member: art.member
                    });
                }
            }).then(() => artworkList?.length && setListBackground(artworkList));
        }

        if (listLengthBackground > 1)
        {
            return (
                <Popup
                    open={openChooserBackground}
                    position="right center"
                    modal
                    closeOnDocumentClick={false}
                >
                    <FrameChooser
                        text="Select Green Screen Background"
                        index={indexBackground >= 0 ? indexBackground : 0}
                        list={listBackground}
                        chooserModal={setChooserModalBackgroundClosed}
                        onClick={() =>
                        {
                            setChooserModalBackgroundClosed();
                            const indexBackgroundNew = galleryBackground.getCurrentIndex();
                            if (indexBackgroundNew !== indexBackground)
                            {
                                setIndexBackground(indexBackgroundNew);
                            }
                        }}
                        refVar={(i) => setGalleryBackground(i)}
                    />
                </Popup>
            );
        }

        setChooserModalBackgroundClosed();
    }

    return null;
}

GreenScreenBackgroundView.defaultProps = {
    openChooserBackground: PropTypes.bool.isRequired,
    orientation: PropTypes.string.isRequired,
    listLengthBackground: PropTypes.number.isRequired,
    setArtworkGreenScreenBackground: PropTypes.func.isRequired,
    setChooserModalBackgroundClosed: PropTypes.func.isRequired,
    artWorkGreenScreenBackgroundIndex: PropTypes.number.isRequired
};

GreenScreenBackgroundView.propTypes = {
    openChooserBackground: PropTypes.bool,
    orientation: PropTypes.string,
    listLengthBackground: PropTypes.number,
    setArtworkGreenScreenBackground: PropTypes.func,
    setChooserModalBackgroundClosed: PropTypes.func,
    artWorkGreenScreenBackgroundIndex: PropTypes.number
};
