import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectImages, selectVideos, selectUploads } from "../../state/selectors";
import TilesView from "./Tiles";

const mapStateToProps = (state) => ({
    images: selectImages(state),
    videos: selectVideos(state),
    uploads: selectUploads(state)
});

const mapDispatchToProps = () => ({
});

const Tiles = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TilesView));

export default Tiles;
