import { connect } from "react-redux";
import FaceView from "./Face";
import { selectCampaign, selectArtworkFace } from "../../../../state/selectors";
import { setArtworkFace } from "../../../../state/actions";

const mapStateToProps = (state) =>
{
    const campaign = selectCampaign(state);
    const listLengthFace = campaign?.facetextures?.length;

    return ({
        listLengthFace,
        artWorkFaceIndex: selectArtworkFace(state)?.index || 0
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkFace: (url, index) => dispatch(setArtworkFace(url, index))
});

const Face = connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceView);

export default Face;
