import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    selectCampaign,
    selectHideHeader,
    selectOrientation,
    selectCampaignID
} from "../../state/selectors";
import { clearCurrentCampaignID, clearCurrentCampaign } from "../../state/actions";
import TagkastNavigationView from "./TagkastNavigation";

const mapStateToProps = (state) => ({
    campaign: selectCampaign(state),
    orientation: selectOrientation(state),
    hideHeader: selectHideHeader(state),
    campaignID: selectCampaignID(state)
});

const mapDispatchToProps = (dispatch) => ({
    clearCurrentCampaign: () => dispatch(clearCurrentCampaign()),
    clearCurrentCampaignID: () => dispatch(clearCurrentCampaignID())
});

const TagkastNavigation = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TagkastNavigationView));

export default TagkastNavigation;
