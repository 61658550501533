import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../../db";
import { FrameChooser } from "../../FrameChooser/FrameChooser";

export default function FaceView({ openChooserFace, setChooserModalFaceClosed, listLengthFace, setArtworkFace,
    artWorkFaceIndex })
{
    const [galleryFace, setGalleryFace] = useState([]);
    const [listFace, setListFace] = useState([]);
    const [indexFace, setIndexFace] = useState(artWorkFaceIndex);

    useEffect(() =>
    {
        getArtwork(`face_${indexFace}`).then((result) =>
        {
            if (result?.data != null)
            {
                const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data));
                setArtworkFace(url, indexFace);
            }
        });
    }, [indexFace, setArtworkFace]);

    if (listLengthFace && openChooserFace)
    {
        if (listFace.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("face").each((art) =>
            {
                artworkList.push({
                    original: art.url,
                    thumbnail: art.url
                });
            }).then(() => artworkList?.length && setListFace(artworkList));
        }

        if (listLengthFace > 1)
        {
            return (
                <Popup
                    open={openChooserFace}
                    position="right center"
                    modal
                    onClose={() => setChooserModalFaceClosed()}
                    closeOnDocumentClick={false}
                >
                    <FrameChooser
                        text="Select Face Texture"
                        index={indexFace >= 0 ? indexFace : 0}
                        list={listFace}
                        chooserModal={setChooserModalFaceClosed}
                        onClick={() =>
                        {
                            setChooserModalFaceClosed();
                            const indexFaceNew = galleryFace.getCurrentIndex();
                            if (indexFaceNew !== indexFace)
                            {
                                setIndexFace(indexFaceNew);
                            }
                        }}
                        refVar={(i) => setGalleryFace(i)}
                    />
                </Popup>
            );
        }

        setChooserModalFaceClosed();
    }

    return null;
}

FaceView.defaultProps = {
    openChooserFace: PropTypes.bool.isRequired,
    listLengthFace: PropTypes.number.isRequired,
    setArtworkFace: PropTypes.func.isRequired,
    setChooserModalFaceClosed: PropTypes.func.isRequired,
    artWorkFaceIndex: PropTypes.number.isRequired
};

FaceView.propTypes = {
    openChooserFace: PropTypes.bool,
    listLengthFace: PropTypes.number,
    setArtworkFace: PropTypes.func,
    setChooserModalFaceClosed: PropTypes.func,
    artWorkFaceIndex: PropTypes.number
};
