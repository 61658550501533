import { connect } from "react-redux";
import { selectCampaign, selectOrientation } from "../../../state/selectors";
import { setArtworkGreenScreenBackground } from "../../../state/actions";
import BackgroundView from "./Background";

const mapStateToProps = (state) =>
{
    const campaign = selectCampaign(state);
    const listLengthBackground = campaign.green_screen_settings.backgrounds.length;

    return ({
        orientation: selectOrientation(state),
        listLengthBackground: listLengthBackground,
        enableGreenScreen: selectCampaign(state).is_green_screen_enabled
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkGreenScreenBackground: (url, index) => dispatch(setArtworkGreenScreenBackground(url, index))
});
const Background = connect(
    mapStateToProps,
    mapDispatchToProps
)(BackgroundView);

export default Background;
