import { connect } from "react-redux";
import { selectArtworkAppBackground, selectOrientation } from "../../../../state/selectors";
import { setArtworkAppBackground } from "../../../../state/actions";
import AppBackgroundView from "./AppBackground";

const mapStateToProps = (state) => ({
    orientation: selectOrientation(state),
    artWorkAppBackgroundIndex: selectArtworkAppBackground(state)?.index || 0
});

const mapDispatchToProps = (dispatch) => ({
    setArtworkAppBackground: (url, index) => dispatch(setArtworkAppBackground(url, index))
});

const AppBackground = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppBackgroundView);

export default AppBackground;
