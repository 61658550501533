import { connect } from "react-redux";
import { selectCampaign, selectCampaignID, selectOrientation, selectTestFlag,
    selectArtworkFrame, selectArtworkGreenScreenBackground
} from "../../state/selectors";
import { setOrientation } from "../../state/actions";
import CameraPreviewView from "./CameraPreview";

const mapStateToProps = (state) => ({
    campaignID: selectCampaignID(state),
    campaign: selectCampaign(state),
    appOrientation: selectOrientation(state),
    testFlag: selectTestFlag(state),
    enableGreenScreen: selectCampaign(state).is_green_screen_enabled,
    frontFacingPreviewFlipped: selectCampaign(state).is_frontfacing_preview_flipped,
    frontFacingCameraFlipped: selectCampaign(state).is_frontfacing_camera_flipped,
    enableFaceMesh: selectCampaign(state).is_facetexture_enabled,
    artWorkFrameUrl: selectArtworkFrame(state).url,
    artWorkGreenScreenBackgroundUrl: selectArtworkGreenScreenBackground(state).url
});

const mapDispatchToProps = (dispatch) => ({
    setAppOrientation: (appOrientation) => dispatch(setOrientation(appOrientation))
});

const CameraPreview = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraPreviewView);

export default CameraPreview;
