import React from "react";
import "./style.css";
import PropTypes from "prop-types";

const TypeBox = ({ svg, name, description, isActive, isEnabled, onClickCallBack }) => (
    <button
        type="button"
        className={`${isEnabled ? "TypeBox" : "TypeBox_Disabled"} ${isActive ? "TypeBox_Active" : ""}`}
        onClick={() => onClickCallBack(name.toLowerCase())}
        key={name}
    >
        <div className="TypeBox_Svg">{svg}</div>
        <div className="TypeBox_Separator" />
        <div className="TypeBox_TextBlock">
            <span className="TypeBox_Name">{name}</span>
            <span className="TypeBox_Description">{description}</span>
        </div>
    </button>
);

TypeBox.defaultProps = {
    svg: PropTypes.shape({
        type: PropTypes.oneOf(["img", "svg"])
    }).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    onClickCallBack: PropTypes.func.isRequired
};

TypeBox.propTypes = {
    svg: PropTypes.shape({
        type: PropTypes.oneOf(["img", "svg"])
    }),
    name: PropTypes.string,
    description: PropTypes.string,
    isActive: PropTypes.bool,
    isEnabled: PropTypes.bool,
    onClickCallBack: PropTypes.func
};

export default TypeBox;
