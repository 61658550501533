import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CampaignInitialization from "./components/CampaignInitialization";
import HomeScreenActivity from "./components/HomeScreenActivity";
import CameraActivity from "./components/CameraActivity";
import AppLoader from "./components/AppLoader";
import HeaderBar from "./components/HeaderBar";
import PhotoShare from "./components/PhotoShare";
import KioskStartScreen from "./components/KioskStartScreen";
import CameraError from "./components/CameraError";
import LeadGenActivity from "./components/LeadGenActivity";
import ContentType from "./components/ScreenSelect/ContentType";
import Background from "./components/ScreenSelect/Background";
import Frame from "./components/ScreenSelect/Frame";
import Face from "./components/ScreenSelect/Face";

export default () => (
    <Switch>
        <Route path="/AppLoader/:campaignCode" component={AppLoader} />
        <Route path="/AppLoader" component={AppLoader} />
        <Route path="/CampaignInitialization" exact component={CampaignInitialization} />
        <Route path="/CampaignInitialization/:campaignCode" exact component={CampaignInitialization} />
        <Route path="/HomeScreenActivity" exact component={HomeScreenActivity} />
        <Route path="/CameraActivity" exact component={CameraActivity} />
        <Route path="/PhotoShare/:type/:ID" exact component={PhotoShare} />
        <Route path="/PhotoShare/:type/:ID/:name" exact component={PhotoShare} />
        <Route path="/KioskStartScreen" exact component={KioskStartScreen} />
        <Route path="/CameraError" exact component={CameraError} />
        <Route path="/LeadGenActivity" exact component={LeadGenActivity} />
        <Route path="/ContentType" exact component={ContentType} />
        <Route path="/Background" exact component={Background} />
        <Route path="/Frame" exact component={Frame} />
        <Route path="/Face" exact component={Face} />
        <Route exact path="/">
            <Redirect to="/AppLoader" />
        </Route>

        <Route component={HeaderBar} />
    </Switch>
);
