import React, { Component } from "react";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import {
    getSetting,
    getCampaignImages,
    blobToArrayBufferObject,
    updateImage
} from "../../db";

export default class AppLoaderView extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirectDestination: null
        };
    }

    componentDidMount()
    {
        const { testFlag } = this.props;

        testFlag && this.fetchList();

        getSetting("campaignID").then((result) => this.processGetSetting(result));
    }

    getSubdomain = () =>
    {
        const hostname = window.location.hostname.match(/([^\n^.^\s]*)\./);
        if (hostname != null)
        {
            return hostname[1];
        }

        return null;
    }

    processGetSetting = (result) =>
    {
        const { match: { params: { campaignCode } } } = this.props;
        const subdomain = this.getSubdomain();
        const settings = result;

        if (settings && campaignCode && campaignCode !== settings.value)
        {
            settings.value = null;
        }

        if (subdomain)
        {
            fetch("/domainRedirect").then((redirectBody) =>
            {
                redirectBody.json().then((domainDict) =>
                {
                    this.processDomainDict(settings, domainDict);
                });
            });
        }

        this.processResult(settings);
    }

    processDomainDict = (result, domainDict) =>
    {
        const subdomain = this.getSubdomain();
        const { setCurrentCampaignID, match: { params: { campaignCode } } } = this.props;
        const campaignID = result?.value;
        let redirectDestination = "/CampaignInitialization";

        console.log(`Checking redirect for ${subdomain}`);
        console.log(`Found ${domainDict[subdomain]}`);

        if ((domainDict[subdomain] != null) &&
            ((campaignID == null) || (campaignID !== campaignCode)))
        {
            console.log("Clearing campaign");
            setCurrentCampaignID(null);
            redirectDestination += `/${domainDict[subdomain]}`;
        }

        this.setState({
            redirectDestination
        });
    }

    processResult = (result) =>
    {
        const {
            match: { params: { campaignCode } },
            setCurrentCampaignID,
            hideHeader,
            showHeader,
            campaign
        } = this.props;
        const subdomain = this.getSubdomain();
        const campaignID = result?.value;
        let redirectDestination;

        if (campaignID == null)
        {
            console.log("No current campaign");
            setCurrentCampaignID(null);

            if (campaignCode)
            {
                this.setState({
                    redirectDestination: `/CampaignInitialization/${campaignCode}`
                });
            }
            else if (!subdomain)
            {
                this.setState({
                    redirectDestination: "/CampaignInitialization"
                });
            }
        }
        else
        {
            setCurrentCampaignID(campaignID);
            hideHeader();
            redirectDestination = "/HomeScreenActivity";

            getCampaignImages(campaignID).then(async (images) =>
            {
                await this.processGetCampaignImagesResult(images);
            });

            showHeader();
            if (campaign && campaign.activation_mode === "kiosk")
            {
                redirectDestination = "/KioskStartScreen";
            }

            this.setState({
                redirectDestination
            });
        }
    }

    processGetCampaignImagesResult = async (result) =>
    {
        const { setImages } = this.props;
        const incomingImages = result;
        const images = [];
        const updatedImages = [];

        for (let i = 0; i < incomingImages.length; i++)
        {
            const { image, id } = incomingImages[i];

            if ((image) && (image instanceof Blob))
            {
                images.push(blobToArrayBufferObject(image));
                updatedImages.push(updateImage(id, image));
            }
        }
        await Promise.all(images);
        await Promise.all(updatedImages);
        setImages(incomingImages);
    }

    fetchList = () =>
    {
        const { setJpgList, setJpgListLength } = this.props;

        fetch("jpg-list.json", { cache: "no-cache" })
            .then((response) => response.json())
            .then((array) =>
            {
                setJpgList(array);
                setJpgListLength(array.length);
            });
    };

    render()
    {
        const { redirectDestination } = this.state;

        if (redirectDestination == null) return "";

        return <Redirect to={`${redirectDestination}`} />;
    }
}

AppLoaderView.defaultProps = {
    setCurrentCampaignID: PropTypes.func.isRequired,
    setJpgList: PropTypes.func.isRequired,
    setJpgListLength: PropTypes.func.isRequired,
    setImages: PropTypes.func.isRequired,
    hideHeader: PropTypes.func.isRequired,
    showHeader: PropTypes.func.isRequired
};

AppLoaderView.propTypes = {
    setCurrentCampaignID: PropTypes.func,
    setJpgList: PropTypes.func,
    setJpgListLength: PropTypes.func,
    setImages: PropTypes.func,
    hideHeader: PropTypes.func,
    showHeader: PropTypes.func
};
