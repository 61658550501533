import React, { Component } from "react";
import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import {
    getArtwork,
    arrayBufferObjectToBlob
} from "../../db";
import "./style.css";

export default class KioskStartScreenView extends Component
{
    constructor(props)
    {
        super(props);
        const { lockOrientation, orientation } = props;
        const newComponentOrientation = (props.lockOrientation !== "none") ? lockOrientation : orientation;

        this.state = {
            kioskBackground: null,
            componentOrientation: newComponentOrientation
        };
    }

    async componentDidMount()
    {
        const { imageWorker, videoWorker, campaign, history } = this.props;

        if (campaign === null)
        {
            console.log("CAMPAIGN NOT INITIALIZED, POPPING BACK");
            history.replace("/CampaignInitialization");

            return;
        }

        if (imageWorker && imageWorker.postMessage)
        {
            imageWorker.postMessage({ messageType: "upload_images" });
            imageWorker.postMessage({ messageType: "upload_surveys" });
        }

        if (videoWorker && videoWorker.postMessage)
        {
            videoWorker.postMessage({ messageType: "upload_videos" });
            videoWorker.postMessage({ messageType: "upload_surveys" });
        }

        await this.updateArtWork();
    }

    async componentDidUpdate()
    {
        this.isOrientationChanged() && await this.updateArtWork();
    }

    updateArtWork = async () =>
    {
        const { orientation, lockOrientation } = this.props;
        const artOrientation = (lockOrientation !== "none") ? lockOrientation : orientation;

        getArtwork(`kiosk_background_${artOrientation}`).then((result) =>
        {
            try
            {
                let newKioskBackground = null;
                if (result != null)
                {
                    const blob = arrayBufferObjectToBlob(result.data, result.mimeType);
                    newKioskBackground = URL.createObjectURL(blob);
                }

                this.setState({
                    kioskBackground: newKioskBackground
                });
            }
            catch (error)
            {
                console.error(error.toLocaleString());
            }
        });
    }

    isOrientationChanged = () =>
    {
        const { orientation, lockOrientation } = this.props;
        const { componentOrientation } = this.state;

        if (lockOrientation !== "none")
        {
            if (componentOrientation !== lockOrientation)
            {
                this.setState({ componentOrientation: lockOrientation });

                return true;
            }

            return false;
        }

        if (orientation && (!componentOrientation || componentOrientation !== orientation))
        {
            this.setState({ componentOrientation: orientation });

            return true;
        }

        return false;
    }

    clickHandler = (e) =>
    {
        const { history, campaign } = this.props;

        const nextHistory = campaign.is_lead_generation_on ? "/LeadGenActivity" : "/ContentType";
        e.preventDefault();
        history.push(nextHistory);
    }

    getImgClassName = () =>
    {
        const { lockOrientation } = this.props;
        let imgClassName = "KioskStartScreenView_Img";

        if (lockOrientation === "portrait")
        {
            imgClassName += " KioskStartScreenView_Img__Portrait";
        }
        else if (lockOrientation === "landscape")
        {
            imgClassName += " KioskStartScreenView_Img__Landscape";
        }

        return imgClassName;
    }

    render()
    {
        const { kioskBackground } = this.state;
        const imgClassName = this.getImgClassName();

        if (!kioskBackground)
        {
            return null;
        }

        return (
            <div
                role="main"
                className="KioskStartScreenView_Container"
            >
                <button
                    className="KioskStartScreenView_Button"
                    type="button"
                    onClick={this.clickHandler}
                >
                    <img
                        className={imgClassName}
                        src={kioskBackground}
                        alt="Kiosk Background"
                    />
                </button>
            </div>
        );
    }
}

KioskStartScreenView.defaultProps = {
    campaign: PropTypes.object.isRequired,
    orientation: PropTypes.string.isRequired,
    lockOrientation: PropTypes.string.isRequired,
    imageWorker: PropTypes.object.isRequired,
    videoWorker: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

KioskStartScreenView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    orientation: PropTypes.string,
    lockOrientation: PropTypes.string,
    imageWorker: PropTypes.shape({ root: PropTypes.string }),
    videoWorker: PropTypes.shape({ root: PropTypes.string }),
    history: PropTypes.shape(historyPropTypes)
};
