import { connect } from "react-redux";
import { selectCampaign } from "../../../state/selectors";
import { setContentType } from "../../../state/actions";
import ContentTypeView from "./ContentType";

const mapStateToProps = (state) => ({
    isLeadGenOn: selectCampaign(state).is_lead_generation_on,
    isPhotoEnabled: selectCampaign(state).is_photo_enabled,
    isGifEnabled: selectCampaign(state).is_gif_enabled,
    isVideoEnabled: selectCampaign(state).is_video_enabled
});

const mapDispatchToProps = (dispatch) => ({
    setContentType: (value) => dispatch(setContentType(value))
});

const ContentType = connect(
    mapStateToProps,
    mapDispatchToProps
)(ContentTypeView);

export default ContentType;
