import { connect } from "react-redux";
import {
    selectCampaign,
    selectTestFlag
} from "../../state/selectors";
import {
    initializeCampaign,
    checkCampaignCode,
    verifyOperator
} from "../../webapi/actions";
import {
    setCurrentCampaignID,
    setCurrentCampaign,
    clearState,
    showHeader,
    hideHeader
} from "../../state/actions";
import CampaignInitializationView from "./CampaignInitialization";

const mapStateToProps = (state) => ({
    testFlag: selectTestFlag(state),
    campaign: selectCampaign(state)
});

const mapDispatchToProps = (dispatch) => ({
    verifyCampaignCode: (code) => dispatch(checkCampaignCode(code)),
    verifyOperator: (operatorID) => dispatch(verifyOperator(operatorID)),
    initializeCampaign: (campaignID, venueID, installationID, fbcmID) => dispatch(
        initializeCampaign(campaignID, venueID, installationID, fbcmID)
    ),
    setCampaign: (campaign) => dispatch(setCurrentCampaign(campaign)),
    setCurrentCampaignID: (campaign) => dispatch(setCurrentCampaignID(campaign)),
    clearState: () => dispatch(clearState()),
    showHeader: () => dispatch(showHeader()),
    hideHeader: () => dispatch(hideHeader())
});

const CampaignInitialization = connect(
    mapStateToProps,
    mapDispatchToProps
)(CampaignInitializationView);

export default CampaignInitialization;
