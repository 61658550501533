import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FaceMesh } from "@mediapipe/face_mesh";
import "./style.css";

const OPTIONS = {
    selfieMode: true,
    enableFaceGeometry: true,
    maxNumFaces: 1,
    refineLandmarks: false,
    minDetectionConfidence: 0.5,
    minTrackingConfidence: 0.5
};

const FaceTransformView = ({ drawCanvasRef, processFaceTransform }) =>
{
    const faceMeshRef = useRef(null);

    const onResults = (results) =>
    {
        if (true || results.multiFaceLandmarks.length > 0)
        {
            processFaceTransform(results.multiFaceGeometry);
        }
    };

    const processFaceMesh = async () =>
    {
        if ((typeof drawCanvasRef.current !== "undefined") && (drawCanvasRef.current !== null))
        {
            await faceMeshRef.current.send({ image: drawCanvasRef.current });
        }
        requestAnimationFrame(processFaceMesh);
    };

    const cleanUpFunc = () =>
    {
        faceMeshRef.current && faceMeshRef.current.close();
    };

    useEffect(() =>
    {
        const initFaceMesh = () =>
        {
            const faceMesh = new FaceMesh({ locateFile: (file) => `/face_mesh/${file}` });

            faceMeshRef.current = faceMesh;

            faceMesh.setOptions(OPTIONS);
            faceMesh.onResults(onResults);

            processFaceMesh();
        };

        initFaceMesh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => () =>
    {
        cleanUpFunc();
    }, []);

    return null;
};

export { FaceTransformView };

FaceTransformView.defaultProps = {
    drawCanvasRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    processFaceTransform: PropTypes.func.isRequired
};

FaceTransformView.propTypes = {
    drawCanvasRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    processFaceTransform: PropTypes.func
};
