import axios from "axios";

export const serverURL = "https://api.tagkast.com";
export const hostURL = ((process.env.NODE_ENV === "development") ? "" : serverURL);

export const checkCampaignCode = (campaignCode) => () => axios.get(
    `${hostURL}/v1/access_codes/input?code=${campaignCode}`
);

export const verifyOperator = (operatorCode) => () => axios.post(
    `${hostURL}/v1/operators/verify`,
    { code: operatorCode }
);

export const initializeCampaign = (campaignID, venueID, installationID, fbcmID) => () => axios.post(
    `${hostURL}/v3/campaign/initialize`,
    {
        campaign_id: campaignID,
        venue_id: venueID,
        installation_id: installationID,
        fbcm_id: fbcmID
    }
);
