import { connect } from "react-redux";
import FrameView from "./Frame";
import { selectCampaign, selectOrientation, selectArtworkFrame } from "../../../../state/selectors";
import { setArtworkFrame } from "../../../../state/actions";

const mapStateToProps = (state) => 
{    
    const campaign = selectCampaign(state);
    const orientation = selectOrientation(state);
    const listLengthFrame = campaign.photo_settings[0].branding.frames.length;

    return ({
        orientation,
        listLengthFrame,
        artWorkFrameIndex: selectArtworkFrame(state)?.index || 0
    });
};

const mapDispatchToProps = (dispatch) => ({
    setArtworkFrame: (url, index) => dispatch(setArtworkFrame(url, index))
});

const Frame = connect(
    mapStateToProps,
    mapDispatchToProps
)(FrameView);

export default Frame;
