import React, { Component } from "react";
import { Nav, NavItem } from "react-bootstrap";
import { MdCameraAlt } from "react-icons/md";
import { history as historyPropTypes } from "history-prop-types";
import PropTypes from "prop-types";
import { updateSetting } from "../../db";

export default class TagkastNavigationView extends Component
{
    onEndSession = () =>
    {
        const { clearCurrentCampaign, clearCurrentCampaignID, history } = this.props;
        const { replace } = history;

        updateSetting("campaignID", null)
            .then(() => updateSetting("campaign", null))
            .then(() =>
            {
                replace("/");
                // The global state is different from what we have stored in the DB. We have to clear both.
                clearCurrentCampaign();
                clearCurrentCampaignID();
            });
    };

    onCameraClick = () =>
    {
        const { history, campaign } = this.props;
        const { is_lead_generation_on: leadGenOn } = campaign;
        const newLink = leadGenOn ? "/LeadGenActivity" : "/CameraActivity";

        history.push(newLink);
    };

    render()
    {
        const { campaignID, campaign, hideHeader } = this.props;
        const { onEndSession, onCameraClick } = this;

        if (campaignID == null || campaignID === "")
        {
            // We are not logged in, so just return an empty nav bar. Users not logged in don't get to take pictures
            return (
                <Nav className="flex-row ml-md-auto d-md-flex" />
            );
        }

        if ((campaign?.activation_mode === "kiosk") || hideHeader)
        {
            // We are a kiosk, so we don't have a nav bar at all!
            return (<div />);
        }

        return (
            <Nav className="flex-row ml-md-auto d-md-flex">
                <NavItem className="nav-link" onClick={onCameraClick}>
                    <MdCameraAlt />
                </NavItem>
                <NavItem className="nav-link ml-md-5" onClick={onEndSession}>
                    End Session
                </NavItem>
            </Nav>
        );
    }
}

TagkastNavigationView.defaultProps = {
    clearCurrentCampaign: PropTypes.func.isRequired,
    clearCurrentCampaignID: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired,
    campaignID: PropTypes.number.isRequired,
    hideHeader: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

TagkastNavigationView.propTypes = {
    clearCurrentCampaign: PropTypes.func,
    clearCurrentCampaignID: PropTypes.func,
    campaign: PropTypes.shape({ root: PropTypes.string }),
    campaignID: PropTypes.number,
    hideHeader: PropTypes.bool,
    history: PropTypes.shape(historyPropTypes)
};
