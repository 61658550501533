import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaceMeshView } from "./FaceMesh";
import { selectArtworkFace, selectCampaign } from "../../state/selectors";

const mapStateToProps = (state) => ({
    frontFacingPreviewFlipped: selectCampaign(state).is_frontfacing_preview_flipped,
    frontFacingCameraFlipped: selectCampaign(state).is_frontfacing_camera_flipped,
    artWorkFaceUrl: selectArtworkFace(state).url
});

const mapDispatchToProps = () => ({
});

const FaceMesh = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceMeshView));

export { FaceMesh };
