import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga";
import App from "./components/App/index";
import * as serviceWorker from "./serviceWorker";
import { globalState, globalPersistor } from "./state";
import "./index.css";

const GoogleAnalyticsID = "UA-38143709-4";

ReactGA.initialize(GoogleAnalyticsID);
ReactDOM.render(
    <Provider store={globalState}>
        <PersistGate loading={null} persistor={globalPersistor}>
            <App />
        </PersistGate>
    </Provider>, document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
