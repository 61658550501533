import React from "react";
import PropTypes from "prop-types";

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p)
{
    return p.toString() === "[object SafariRemoteNotification]";
}(!window.safari || (typeof safari !== "undefined" /* && safari.pushNotification */)));

export default class AppInfoView extends React.Component
{
    componentDidMount = () =>
    {
        window.addEventListener("orientationchange", this.onOrientationChange);
        window.addEventListener("resize", this.onResize);
        this.onResize();
        this.onOrientationChange();
    }

    onResize = () =>
    {
        const { setAppOrientation, appOrientation, lockOrientation } = this.props;

        if ((lockOrientation === "landscape") || (lockOrientation === "portrait"))
        {
            if (appOrientation !== lockOrientation)
            {
                setAppOrientation(lockOrientation);
            }

            return;
        }

        if (appOrientation !== "landscape")
        {
            if (window.innerHeight < window.innerWidth)
            {
                setAppOrientation("landscape");
            }
        }

        if (appOrientation !== "portrait")
        {
            if (window.innerHeight > window.innerWidth)
            {
                setAppOrientation("portrait");
            }
        }
    }

    onOrientationChange = () =>
    {
        const { setAppOrientation, appOrientation, lockOrientation } = this.props;

        if ((lockOrientation === "landscape") || (lockOrientation === "portrait"))
        {
            if (appOrientation !== lockOrientation)
            {
                setAppOrientation(lockOrientation);
            }

            return;
        }

        if ((window.matchMedia("(orientation: portrait)").matches) && (appOrientation !== "portrait"))
        {
            setAppOrientation("portrait");
        }

        if ((window.matchMedia("(orientation: landscape)").matches) && (appOrientation !== "landscape"))
        {
            setAppOrientation("landscape");
        }
    }

    render()
    {
        return null;
    }
}

AppInfoView.defaultProps = {
    appOrientation: PropTypes.string.isRequired,
    setAppOrientation: PropTypes.func.isRequired,
    lockOrientation: PropTypes.string.isRequired
};

AppInfoView.propTypes = {
    appOrientation: PropTypes.string,
    setAppOrientation: PropTypes.func,
    lockOrientation: PropTypes.string
};
