import ACTIONS from "./actions";

const defaultState = {
    campaignID: 0,
    campaign: 0, // it was null
    imageWorker: null,
    videoWorker: null,
    hideHeader: true,
    images: [],
    videos: [],
    uploads: [],
    orientation: null,
    lastSurveyId: -1,
    jpgList: [],
    isProcessPhotosStarted: false,
    isProcessPhotosReady: false,
    jpgListLength: 0,
    testFlag: false,
    artworks: {
        frame: {
            url: "",
            index: -1
        },
        greenScreenBackground: {
            url: "",
            index: -1
        },
        appBackground: {
            url: "",
            index: -1
        },
        face: {
            url: "",
            index: -1
        }
    }
};

const globalReducer = (state = defaultState, { type, payload }) =>
{
    let imageIdx = -1;
    let videoIdx = -1;

    switch (type)
    {
        case ACTIONS.Types.HIDE_HEADER:
            return { ...state, hideHeader: true };

        case ACTIONS.Types.SHOW_HEADER:
            return { ...state, hideHeader: false };

        case ACTIONS.Types.SET_CAMPAIGN_ID:
            return { ...state, campaignID: payload };

        case ACTIONS.Types.CLEAR_CAMPAIGN_ID:
            return { ...state, campaignID: null };

        case ACTIONS.Types.SET_CAMPAIGN:
            return { ...state, campaign: payload };

        case ACTIONS.Types.CLEAR_CAMPAIGN:
            return { ...state, campaign: null };

        case ACTIONS.Types.SET_ORIENTATION:
            return { ...state, orientation: payload };

        case ACTIONS.Types.SET_IMAGE_WORKER:
            return { ...state, imageWorker: payload };

        case ACTIONS.Types.SET_UPLOAD_PROGRESS:
            if (!state.uploads)
            {
                state.uploads = [];
            }
            if (state.uploads[payload.filename] != null &&
                state.uploads[payload.filename].uploaded === payload.uploaded &&
                state.uploads[payload.filename].total === payload.total &&
                state.uploads[payload.filename].uploadError === payload.uploadError)
            {
            // This is a shallow copy, and will NOT cause a rerender
                return { ...state, uploads: state.uploads };
            }
            const newUploads = [...state.uploads];
            newUploads[payload.filename] = payload;

            return { ...state, uploads: newUploads };

        case ACTIONS.Types.SET_IMAGE_URL:
            if (state.images == null)
            {
                state.images = [];
            }
            for (let i = 0; i < state.images.length; i++)
            {
                if (state.images[i].id === payload.imageID)
                {
                    imageIdx = i;
                    break;
                }
            }

            if (imageIdx !== -1)
            {
                const newImages = [...state.images];
                newImages[imageIdx].url = payload.url;
                newImages[imageIdx].image_url = payload.image_url;
                newImages[imageIdx].isUploaded = 1;

                return { ...state, images: newImages };
            }
            // This is a shallow copy, and will NOT cause a rerender

            return { ...state, uploads: state.uploads };

        case ACTIONS.Types.SET_IMAGES:
            if (state.images == null)
            {
                state.images = [];
            }

            return { ...state, images: payload };

        case ACTIONS.Types.ADD_IMAGE:
            if (state.images == null)
            {
                state.images = [];
            }
            const newImages = [...state.images];
            newImages.push(payload);

            return { ...state, images: newImages };

        case ACTIONS.Types.SET_LAST_SURVEY_ID:
            return { ...state, lastSurveyId: payload };

        case ACTIONS.Types.SET_JPG_LIST:
            return { ...state, jpgList: payload };

        case ACTIONS.Types.SET_IS_PROCESS_PHOTOS_STARTED:
            return { ...state, isProcessPhotosStarted: payload };

        case ACTIONS.Types.SET_IS_PROCESS_PHOTOS_READY:
            return { ...state, isProcessPhotosReady: payload };

        case ACTIONS.Types.CLEAR_STATE:
            return { ...state,
                isProcessPhotosStarted: false,
                isProcessPhotosReady: false,
                videos: [],
                uploads: []
            };

        case ACTIONS.Types.SET_JPG_LIST_LENGTH:
            return { ...state, jpgListLength: payload };

        case ACTIONS.Types.SET_TEST_FLAG:
            return { ...state, testFlag: payload };

        case ACTIONS.Types.SET_VIDEO_WORKER:
            return { ...state, videoWorker: payload };

        case ACTIONS.Types.SET_VIDEO_URL:
            if (state.videos == null)
            {
                state.videos = [];
            }
            for (let i = 0; i < state.videos.length; i++)
            {
                if (state.videos[i].id === payload.videoID)
                {
                    videoIdx = i;
                    break;
                }
            }

            if (videoIdx !== -1)
            {
                const newVideos = [...state.videos];
                newVideos[videoIdx].url = payload.url;
                newVideos[videoIdx].video_url = payload.video_url;
                newVideos[videoIdx].isUploaded = 1;

                return { ...state, videos: newVideos };
            }
            // This is a shallow copy, and will NOT cause a rerender

            return { ...state, uploads: state.uploads };

        case ACTIONS.Types.SET_VIDEOS:
            if (state.videos == null)
            {
                state.videos = [];
            }

            return { ...state, videos: payload };

        case ACTIONS.Types.ADD_VIDEO:
            if (state.videos == null)
            {
                state.videos = [];
            }
            const newVideos = [...state.videos];
            newVideos.push(payload);

            return { ...state, videos: newVideos };

        case ACTIONS.Types.SET_ARTWORK_FRAME:
            return { ...state,
                artworks: {
                    ...state.artworks,
                    frame: payload
                } };

        case ACTIONS.Types.SET_ARTWORK_GREENSCREEN_BACKGROUND:
            return { ...state,
                artworks: {
                    ...state.artworks,
                    greenScreenBackground: payload
                } };

        case ACTIONS.Types.SET_ARTWORK_APP_BACKGROUND:
            return { ...state,
                artworks: {
                    ...state.artworks,
                    appBackground: payload
                } };

        case ACTIONS.Types.SET_ARTWORK_FACE:
            return { ...state,
                artworks: {
                    ...state.artworks,
                    face: payload
                } };

        case ACTIONS.Types.SET_CONTENT_TYPE:
            return { ...state, contentType: payload };

        default:
            return state;
    }
};

export default globalReducer;
