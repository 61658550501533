import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import PropTypes from "prop-types";
import TagkastNavigation from "../TagkastNavigation";

export default class HeaderBarView extends Component
{
    render()
    {
        const { campaign, hideHeader } = this.props;

        if (campaign?.activation_mode === "kiosk" || hideHeader)
        {
            // We are a kiosk, so we don't have a nav bar at all!
            return (<div />);
        }

        return (
            <header className="tagkastNavigationBar">
                <Navbar collapseOnSelect className="navbar-dark flex-column flex-md-row bd-navbar">
                    <Navbar.Brand as={Link} to="/">
                        <img
                            className="tagkastNavbarLogo mr-0 mr-md-2"
                            alt="Tagkast Logo"
                        />
                    </Navbar.Brand>
                    <TagkastNavigation />
                </Navbar>
            </header>
        );
    }
}

HeaderBarView.defaultProps = {
    campaign: PropTypes.object.isRequired,
    hideHeader: PropTypes.bool.isRequired
};

HeaderBarView.propTypes = {
    campaign: PropTypes.shape({ root: PropTypes.string }),
    hideHeader: PropTypes.bool
};
