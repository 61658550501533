import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import globalReducer from "./reducer";

/* eslint-disable no-return-await */

const persistConfig = {
    key: "root",
    storage
};

const persistedReducer = persistReducer(persistConfig, globalReducer);

const initStore = () =>
{
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const initState = {
        campaignID: 0,
        campaign: null,
        isProcessPhotosStarted: false,
        isProcessPhotosReady: false,
        jpgListLength: 0,
        testFlag: false,
        images: [],
        videos: [],
        uploads: [],
        hideHeader: true,
        lastSurveyId: -1,
        artworks: {
            frame: {
                url: "",
                index: -1
            },
            greenScreenBackground: {
                url: "",
                index: -1
            },
            appBackground: {
                url: "",
                index: 0
            },
            face: {
                url: "",
                index: -1
            }
        },
        contentType: ""
    };

    const store = createStore(
        persistedReducer,
        initState,
        composeEnhancers(process.env.NODE_ENV === "development" ?
            applyMiddleware(thunk, logger) :
            applyMiddleware(thunk)));

    const persistor = persistStore(store);

    return { store, persistor };
};

const globalStore = initStore();
const {
    store: globalState,
    persistor: globalPersistor
} = globalStore;

export {
    globalState,
    globalStore,
    globalPersistor
};
