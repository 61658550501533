import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { arrayBufferObjectToBlob, db, getArtwork } from "../../../../db";
import { FrameChooser } from "../../FrameChooser/FrameChooser";

export default function FrameView({ openChooserFrame, setChooserModalFrameClosed, orientation,
    listLengthFrame, setArtworkFrame, artWorkFrameIndex })
{
    const [galleryFrame, setGalleryFrame] = useState([]);
    const [listFrame, setListFrame] = useState([]);
    const [indexFrame, setIndexFrame] = useState(artWorkFrameIndex);

    useEffect(() =>
    {
        getArtwork(`frame_${orientation}_${indexFrame}`).then((result) =>
        {
            if (result?.data != null)
            {
                const url = URL.createObjectURL(arrayBufferObjectToBlob(result.data));
                setArtworkFrame(url, indexFrame);
            }
        });
    }, [orientation, indexFrame, setArtworkFrame]);

    if (listLengthFrame && openChooserFrame)
    {
        if (listFrame.length === 0)
        {
            const artworkList = [];
            db.artwork.where("artType").equalsIgnoreCase("frame").each((art) =>
            {
                if (art.member.endsWith(orientation))
                {
                    artworkList.push({
                        original: art.url,
                        thumbnail: art.url
                    });
                }
            }).then(() => artworkList?.length && setListFrame(artworkList));
        }

        if (listLengthFrame > 1)
        {
            return (
                <Popup
                    open={openChooserFrame}
                    position="right center"
                    modal
                    onClose={() => setChooserModalFrameClosed()}
                    closeOnDocumentClick={false}
                >
                    <FrameChooser
                        text="Select Photo Overlay"
                        index={indexFrame >= 0 ? indexFrame : 0}
                        list={listFrame}
                        chooserModal={setChooserModalFrameClosed}
                        onClick={() =>
                        {
                            setChooserModalFrameClosed();
                            const indexFrameNew = galleryFrame.getCurrentIndex();
                            if (indexFrameNew !== indexFrame)
                            {
                                setIndexFrame(indexFrameNew);
                            }
                        }}
                        refVar={(i) => setGalleryFrame(i)}
                    />
                </Popup>
            );
        }

        setChooserModalFrameClosed();
    }

    return null;
}

FrameView.defaultProps = {
    openChooserFrame: PropTypes.bool.isRequired,
    listLengthFrame: PropTypes.number.isRequired,
    orientation: PropTypes.string.isRequired,
    setArtworkFrame: PropTypes.func.isRequired,
    setChooserModalFrameClosed: PropTypes.func.isRequired,
    artWorkFrameIndex: PropTypes.number.isRequired
};

FrameView.propTypes = {
    openChooserFrame: PropTypes.bool,
    listLengthFrame: PropTypes.number,
    orientation: PropTypes.string,
    setArtworkFrame: PropTypes.func,
    setChooserModalFrameClosed: PropTypes.func,
    artWorkFrameIndex: PropTypes.number
};
