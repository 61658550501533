import { connect } from "react-redux";
import {
    selectTestFlag,
    selectIsProcessPhotosReady,
    selectCampaign,
    selectContentType
} from "../../../state/selectors";
import CameraBtnsView from "./CameraBtns";

const mapStateToProps = (state) => ({
    testFlag: selectTestFlag(state),
    isProcessPhotosReady: selectIsProcessPhotosReady(state),
    campaign: selectCampaign(state),
    enableFaceMesh: selectCampaign(state).is_facetexture_enabled,
    contentType: selectContentType(state)
});

const mapDispatchToProps = () => ({
});

const CameraBtns = connect(
    mapStateToProps,
    mapDispatchToProps
)(CameraBtnsView);

export default CameraBtns;
