import React from "react";
import "./style.css";

export const ProcessingText = () => (
    <div className="ProcessingText">
        <div className="ProcessingText_Background" />
        <span className="ProcessingText_Span"> Processing Video... </span>
    </div>
);

ProcessingText.defaultProps = {
};

ProcessingText.propTypes = {

};
