import { connect } from "react-redux";
import {
    selectCampaign,
    selectImageWorker,
    selectVideoWorker
} from "../../state/selectors";
import { setLastSurveyID } from "../../state/actions";
import LeadGenActivityView from "./LeadGenActivity";

const mapStateToProps = (state) => ({
    campaign: selectCampaign(state),
    imageWorker: selectImageWorker(state),
    videoWorker: selectVideoWorker(state)
});

const mapDispatchToProps = (dispatch) => ({
    setLastSurveyID: (surveyId) => dispatch(setLastSurveyID(surveyId))
});

const LeadGenActivity = connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadGenActivityView);

export default LeadGenActivity;
