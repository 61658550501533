import React, { Component } from "react";
import PropTypes from "prop-types";
import { history as historyPropTypes } from "history-prop-types";
import { ProgressBar } from "react-bootstrap";
import { arrayBufferObjectToBlob } from "../../db";
import "./style.css";

export default class TileView extends Component
{
    clickHandler = (e) =>
    {
        const { history, data, type } = this.props;

        e.preventDefault();
        history.push(`/PhotoShare/${type}/${data?.id}`);
    }

    handleVideoRetry = (e) =>
    {
        const { error } = e.target;
        let videoTarget;

        switch (error.code)
        {
            case error.MEDIA_ERR_ABORTED:
                console.log("You aborted the video playback.");
                break;

            case error.MEDIA_ERR_DECODE:
                console.log("The video playback was aborted due to a corruption problem " +
                    "or because the video used features your browser did not support.");
                break;

            case error.MEDIA_ERR_NETWORK:
            case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            default:
                console.log("The video could not be loaded, either because the server " +
                    "or network failed or because the format is not supported.");
                videoTarget = e.target;
                setTimeout(() => 
                {
                    videoTarget.load();
                }, 1000);
                break;
        }
    }

    getTileImage = () =>
    {
        const { upload, data } = this.props;
        const { array, mimeType } = data.image;
        const uploadProgress = upload?.uploadProgress;
        const uploadError = upload?.uploadError;

        const imgUrl = URL.createObjectURL(arrayBufferObjectToBlob(array, mimeType));
        const tileBlock = data.isUploaded ? "Tile_BlockNone" : "Tile_Block";
        const tileClassName = `Tile ${tileBlock}`;
        const roundUploadProgress = Math.round(uploadProgress);
        const progressClassName = (uploadProgress < 100 && uploadError == null) ? "Tile_BlockNone" : "Tile_Block";
        const progressString = `${roundUploadProgress}% uploaded...`;
        const errorBlock = !uploadError ? "Tile_BlockNone" : "Tile_Block";
        const errorClassName = `Tile_Error ${errorBlock}`;
        const progressBarClassName = (isNaN(uploadProgress) || data.isUploaded) ? "Tile_BlockNone" : "Tile_Block";

        return (
            <div>
                <img
                    className="tagkast-gallery-thumbnail img-thumbnail"
                    onClick={this.clickHandler}
                    src={imgUrl}
                    alt={data.name}
                    role="presentation"
                />
                <div className={tileClassName}>
                    <div className={progressClassName}>
                        {progressString}
                    </div>
                    <div className={errorClassName}>
                        Error uploading
                    </div>
                    <ProgressBar
                        now={uploadProgress}
                        label={`${roundUploadProgress}%`}
                        className={progressBarClassName}
                    />
                </div>
            </div>
        );
    }

    getTileVideo = () => 
    {
        const { data } = this.props;

        return (
            <video
                className="tagkast-gallery-thumbnail img-thumbnail"
                onClick={this.clickHandler}
                onError={this.handleVideoRetry}
                src={data.url}
                autoPlay
                playsInline
                muted
            />
        );
    }

    render() 
    {
        const { type } = this.props;
        const { getTileImage, getTileVideo } = this;

        const tile = (type === "image") ? getTileImage() : getTileVideo();

        return (
            <div className="tile m-1">
                { tile }
            </div>
        );
    }
}

TileView.defaultProps = {
    type: PropTypes.string.isRequired,
    history: PropTypes.shape(historyPropTypes),
    upload: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};

TileView.propTypes = {
    type: PropTypes.string,
    history: PropTypes.shape(historyPropTypes),
    upload: PropTypes.shape({ root: PropTypes.string }),
    data: PropTypes.shape({ root: PropTypes.string })
};
