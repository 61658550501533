const StandardShader = {

    uniforms: {
        cameraTexture: { value: null },
        frameTexture: { value: null },
        backgroundTexture: { value: null },
        dataTexture: { value: null },
        alphaTexture: { value: null },
        width: { value: 1024.0 },
        height: { value: 1024.0 }
    },

    vertexShader: [
        "varying vec2 vUvFrame;",
        "varying vec2 vUvBackground;",
        "varying vec2 vUvCamera;",
        "attribute vec2 texcoord1;",
        "attribute vec2 texcoord2;",
        "attribute vec2 texcoord3;",
        "void main() {",

        "	vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );",
        "	gl_Position = projectionMatrix * mvPosition;",
        "   vUvFrame = texcoord1;",
        "   vUvBackground = texcoord2;",
        "   vUvCamera = texcoord3;",

        "}"

    ].join("\n"),

    fragmentShader: [
        "varying vec2 vUvFrame;",
        "varying vec2 vUvBackground;",
        "varying vec2 vUvCamera;",
        "uniform float width;",
        "uniform float height;",
        "uniform sampler2D cameraTexture;",
        "uniform sampler2D frameTexture;",
        "uniform sampler2D backgroundTexture;",
        "uniform sampler2D dataTexture;",
        "float offset[3];\n", // " = float[3](0.0, 1.3846153846, 3.2307692308);\n" +
        "float weight[3];\n", // " = float[3](0.2270270270, 0.3162162162, 0.0702702703);\n",

        "void main() {",

        "offset[0] = 0.0;\n",
        "offset[1] = 1.3846153846;\n",
        "offset[2] = 3.2307692308;\n",
        "weight[0] = 0.2270270270;\n",
        "weight[1] = 0.3162162162;\n",
        "weight[2] = 0.0702702703;\n",
        "vec3 color = texture2D( cameraTexture, vUvCamera ).rgb;",
        "vec4 frameColor = texture2D(frameTexture, vUvFrame).rgba;",
        "vec3 backgroundColor = texture2D(backgroundTexture, vUvBackground).rgb;",
        "float alpha = (1.0 - texture2D(dataTexture, vec2(vUvCamera.x, 1.0 - vUvCamera.y)).a);",
        "vec3 blendedColor = color * (1.0 - alpha) + backgroundColor.rgb * (alpha);",
        "vec4 pixelColor = vec4( blendedColor * (1.0 - frameColor.a) + frameColor.rgb * frameColor.a, 1.0 );",
        /*
        "if (frameColor.a >= 0.5) {",
        "   gl_FragDepth = 0.01;",
        "} else {",
        "   gl_FragDepth = gl_FragCoord.z;",
        "}",
         */
        "gl_FragColor = pixelColor;",
        "}"

    ].join("\n")

};

export { StandardShader };
