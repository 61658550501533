import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FaceTransformView } from "./FaceTransform";

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const FaceTransform = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceTransformView));

export { FaceTransform };
